<template>
    <div>
        <div class="product" :class="(list.length / 4 > 1) ? 'mr' : 'f-c-a'">
            <el-card class="product-card" v-for="(item, index) of list" :key="index">
                <div style="display: block;position: relative;" @click="toDetailPage(item.productId)">
                    <span class="product-similarity" v-if="item.score">相似度：{{ (Math.round(item.score * 10000)) / 100 +
                        '%'
                    }}</span>
                    <el-image class="product-img" :src="item.img1Url" fit="contain"></el-image>
                    <div class="f-cl-c">
                        <span class="product-name">{{ item.productName }}</span>
                        <span class="product-price">{{ item.unitPrice ? item.valuationCurrency : '' }}{{
                            item.unitPrice
                            ?
                            item.unitPrice.toFixed(2) : '暂无报价'
                        }}</span>
                        <div class="product-number f-c" v-if="item.productNumber">
                            <div class="whiteSpaceNo">商品编号:</div>
                            <span class="wordBreak-all">{{ item.productNumber }}</span>
                        </div>
                        <!-- 库存数量为0或者缺货标记为Y则为售罄 缺货标记默认不设置返回N-->
                        <span class="product-state n-state" v-if="item.shortageFlag == 'Y'">售罄</span>
                        <span class="product-state y-state" v-else>在售</span>
                    </div>
                    <!-- 角标 -->
                    <div class="cornermark">{{ item.productTypeStr }}</div>
                </div>
            </el-card>
        </div>
        <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
    </div>
</template>
<script>

export default {
    inject: ['reload'],
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loginBoxVisible: false,
        }
    },
    methods: {
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId,
                            isshowsupply: false,
                        }))
                    }
                })
            }
        },
        CloseLoginBox() {
            this.loginBoxVisible = false;
            if (this.$getStorage('token')) {
                let InvestigateFlag = this.$getStorage('InvestigateFlag');
                this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
            }
        },
    }
}
</script>
<style lang="less" scoped>
.product {
    flex-wrap: wrap;

    .product-card {
        width: 22%;
        margin-top: 20px;

        &:hover {
            transform: translateY(-5px);
        }

        .product-img {
            width: 100%;
            margin: auto;
            height: 210px;

        }

        .product-similarity {
            font-size: 12px;
            color: red;
            display: block;
            text-align: left;
            margin-bottom: 15px;

        }

        .product-name {
            margin: 8px 0;
            display: inline-block;
            word-break: break-all;
        }

        .product-number {
            font-size: 14px;
        }

        .product-price {
            font-size: 14px;
            color: red;
            margin-bottom: 10px;
        }

        .product-state {

            font-size: 14px;
            align-self: center;
            margin-top: 10px;
        }

        .n-state {
            color: #6a6a6a;
        }

        .y-state {
            color: #21af60;
        }

        .cornermark {
            position: absolute;
            right: -15px;
            top: -15px;
            border-radius: 5px;
            background: #ffffff;
            text-align: center;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            color: #699EF2;
            padding: 5px;
        }
    }
}

.mr {
    display: flex;

    .product-card:not(:nth-child(4n)) {
        margin-right: 40px;
    }
}
</style>