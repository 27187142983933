<template>
    <el-cascader ref="cascaderMenu" size="medium" separator=" " :options="optionsCity" v-model="cityValue"
        @change="ChangeCity" :disabled="disabledChange" :append-to-body="false" :props="{ checkStrictly: true,expandTrigger: 'hover' }">
        <!-- <div>qveding</div> -->
    </el-cascader>
</template>

<script>
import provinceOption from "@/assets/pc-pac-a.json";

export default {
    name: '',
    props: {
        city: {
            default: ''
        },
        disabledChange: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionsCity: [],
            cityValue: this.city,
            cascaderKey: 0
        }
    },
    created() {
        this.getProvinceList()
    },
    methods: {
        // 获取省市区
        getProvinceList() {
            let provinceList = provinceOption,
                newarr = [];
            provinceList.map((item) => {
                item.value = item.name;
                item.label = item.name;
                if (item.children && item.children.length) {
                    item.children.map((city) => {
                        city.value = city.name;
                        city.label = city.name;
                        if (city.children && city.children.length) {
                            city.children.forEach((i) => {
                                i.label = i.name;
                                i.value = i.name;
                            })
                        }
                    })
                }
                newarr.push(item);
            })
            this.optionsCity = newarr;
        },
        // 选中省市区
        ChangeCity(value) {
            let address =''
            if(value[2] == '全市'){
                address = value.slice(0, value.length-1);
            }else if(value[1] == '全省(直辖市)'){
                address = value.slice(0, value.length-1);
            }else {
                address = value;
            }
            this.$emit('handleChangeCity', address);
            this.$refs.cascaderMenu.toggleDropDownVisible();
        },

    },
}

</script>
