<template>
    <div class="login-box" :style="LoginPageStyle">
        <div class="login-nav f-c-c" v-if="navActive == 0 || navActive == 1">
            <div class="mr-80" :class="{ active: navActive == 0 }" @click="changePlate(0)">短信登录</div>
            <div :class="{ active: navActive == 1 }" @click="changePlate(1)">密码登录</div>
        </div>
        <div class="login-nav" v-if="navActive == 2">
            <div>注册</div>
        </div>
        <div class="login-nav" v-if="navActive == 3">
            <div>找回密码</div>
        </div>
        <!-- 短信登陆 -->
        <el-form ref="shorMsgForm" :model="shortMsgForm" label-width="70px" v-if="navActive == 0" :rules="shortMsgRules"
            label-position="left">
            <el-form-item prop="phone" label="手机号">
                <PhoneInput :modelValue="shortMsgForm.phone" @getPhoneNumber="getPhoneNumber" @clearPhone="clearPhone">
                </PhoneInput>
            </el-form-item>
            <el-form-item prop="code" label="验证码">
                <GetCode :phone="shortMsgForm.phone" :type="'shorMsgForm'"></GetCode>
            </el-form-item>
            <div class="f-c">
                <div style="margin-left: 196px;" class="login-submit" @click.prevent="login('shorMsgForm')">立即登录/注册</div>
            </div>

        </el-form>

        <!-- 密码登陆 -->
        <el-form ref="countForm" :model="countForm" label-width="70px" v-if="navActive == 1" :rules="countRules"
            label-position="left">
            <el-form-item prop="phone" label="手机号">
                <PhoneInput :modelValue="countForm.phone" :prefixIcon="'el-icon-user'" @getPhoneNumber="getPhoneNumber"
                    @clearPhone="clearPhone">
                </PhoneInput>
            </el-form-item>
            <el-form-item prop="password" label="密码">
                <PwdInput :prefixIcon="'el-icon-lock'" :modelValue="countForm.password" @getPwdValue="getPwdValue">
                </PwdInput>
            </el-form-item>

            <div class="f-c-b">
                <span class="register"></span>
                <button class="login-submit" @click.prevent="login('countForm')">登录</button>
                <span class="forget-pwd" @click="navActive = 3">忘记密码</span>
            </div>
        </el-form>
        <!-- 注册 -->
        <RegisterForm v-if="navActive == 2" type="login">
            <div slot="other" class="back" @click="RegisterBack">返回</div>
        </RegisterForm>
        <!-- 找回密码 -->
        <ForgotPwdForm v-if="navActive == 3" type="login">
            <div slot="other" class="back" @click="navActive = 1">返回</div>
        </ForgotPwdForm>
    </div>
</template>

<script>
import { countRules, shortMsgRules } from "@/utils/FormRules";
import des from '@/utils/DES';
export default {
    name: '',
    props: {
        LoginPageStyle: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            // 需要获取验证码的都不能够进行code验证
            navActive: 0,//0-短信登陆 1-密码登陆 2-注册 3-找回密码
            // 账户表单
            countForm: {
                phone: "",
                password: ""
            },
            // 账号规则
            countRules,
            // 短信表单
            shortMsgForm: {
                phone: "",
                code: ""
            },
            // 短信规则
            shortMsgRules,
            Popup: '',
        }
    },
    created() {
        let navActive = this.$getStorage('navActive') != null ? this.$getStorage('navActive') : this.navActive,
            phone = this.$getStorage('inputPhone') ? this.$getStorage('inputPhone') : ''
        this.navActive = navActive
        this.shortMsgForm.phone = phone
        this.countForm.phone = phone
    },
    methods: {
        // 获取手机号
        getPhoneNumber(data) {
            let { value } = data;
            if (this.navActive == 0) {
                this.shortMsgForm.phone = value
            }
            if (this.navActive == 1) {
                this.countForm.phone = value
            }
            this.$setStorage('inputPhone', value)
        },
        // 获取账号登陆中的密码登陆
        getPwdValue(data) {
            this.countForm.password = data
        },
        // 清空手机号
        clearPhone() {
            this.countForm.phone = '';
            this.shortMsgForm.phone = '';
            this.$removeStorage('inputPhone');
        },
        // 切换模块
        changePlate(data) {
            if (this.$getStorage('inputPhone')) {
                let phone = this.$getStorage('inputPhone');
                this.countForm.phone = phone
                this.shortMsgForm.phone = phone
                this.countForm.password = ''
            }
            this.navActive = data
        },
        // 注册返回按钮
        RegisterBack() {
            this.navActive = 0;
            this.$removeStorage('codeValue')
        },
        handleClose() {
            this.$parent.handleClose()
        },
        // 登陆
        login(form) {
            let that = this, data = null, url = '',
                codeValue = that.$getStorage('codeValue');
            if (codeValue && codeValue.type == 'shorMsgForm') {
                that.shortMsgForm.code = codeValue.code
            }
            that.$refs[form].validate((valid) => {
                if (valid) {
                    if (form == 'shorMsgForm') {
                        data = {
                            phone: that.shortMsgForm.phone ? that.shortMsgForm.phone : that.$getStorage('inputPhone'),
                            code: that.shortMsgForm.code,
                            source: 'p',
                            spType: that.$getStorage('spType')
                        };
                        url = 'user/phoneCodeLogin'
                    } else {
                        data = {
                            account: that.countForm.phone ? that.countForm.phone : that.$getStorage('inputPhone'),
                            passwd: des.encrypt(that.countForm.password),
                            source: 'p',
                            psState: 'Y',
                            spType: that.$getStorage('spType')
                        };
                        url = 'user/login'
                    }
                    that.$apiPost(url, data).then(res => {
                        if (res.code == 200) {
                            let { token, userInfo } = res.data;
                            that.$setStorage('token', token);
                            that.$setStorage('spType', userInfo.spType);
                            that.$store.commit('spType', userInfo.spType);
                            that.$removeStorage('reflush');
                            if(userInfo.supplyInvestigateFlag == 'N' || userInfo.procureInvestigateFlag == 'N'){
                                that.$setStorage('reflush',true);
                            }
                            that.$setStorage('InvestigateFlag', userInfo.spType == 'S' ? userInfo.supplyInvestigateFlag : userInfo.procureInvestigateFlag);
                            that.$store.commit('saveUserName', userInfo.userName)
                            that.$store.commit('saveUserId', userInfo.userId)
                            that.$store.commit('saveUserAvatarUrl', userInfo.userAvatarUrl)
                            that.$store.commit('saveUserPhone', userInfo.phone)
                            // that.$setStorage('tokenDate', that.$common.timeToYMD());
                            let timeUpdate = new Date().getTime();// 获取当前的时间戳
                            that.$setStorage('timeUpdate',timeUpdate);

                            that.$removeStorage('codeValue');
                            that.$removeStorage('navActive');
                            that.$removeStorage('inputPhone');
                            that.$emit("handleisJodVisible", true)
                            that.handleClose()
                            that.$common.message(res.msg, 'success');
                            
                        } else {
                            that.$common.message(res.msg, 'error')
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang='less'>
@import '@/assets/css/mixins.less';

.login-box {
    // width: 688px;
    background: #fff;
    // padding: 25px;
    font-size: 18px;
    // margin-top: 50px;
    // margin-right: 10%;
    // float: right;
    padding-bottom: 30px;

    .login-nav {
        font-size: 30px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 30px;
        // .f1();
        // flex('', space-around, center);

        div {
            text-align: center;
        }

        &:hover {
            cursor: pointer;
        }

        .active {
            color: #000000;
            font-weight: bold;
        }
    }

    .forget-pwd,
    .register {
        cursor: pointer;
        color: #666;
        text-decoration: none;
        align-self: flex-end;
        font-size: 14px;

        &:hover {
            color: #e4393c;
            text-decoration: underline;
        }
    }

    .login-submit {
        width: 160px !important;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-radius: 10px;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        color: #fff;
        font-size: 16px;
        font-family: 'Microsoft YaHei';
        border: none;
        cursor: pointer;
    }

    .back {
        font-size: 14px;

    }

    .back:hover {
        color: #e4393c;
        cursor: pointer;
    }


}
</style>
