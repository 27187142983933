import debounce from './debounce'
import stopNumberMousewhell from './stopNumberMousewhell'


// 自定义指令
const directives = {
    debounce,
    stopNumberMousewhell,
}

export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key])
        })
    },
}