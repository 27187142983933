<template>
    <el-cascader ref="cascaderMenu" size="medium" separator=" " :options="optionsCity" v-model="cityValue"
        @change="ChangeCity" :disabled="disabledChange" :append-to-body="false">
    </el-cascader>
</template>

<script>
import provinceOption from "@/assets/pca-code.json";

export default {
    name: '',
    props: {
        city: {
            default: ''
        },
        disabledChange: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionsCity: [],
            cityValue: this.city,
            cascaderKey: 0
        }
    },
    created() {
        this.getProvinceList()
    },
    methods: {
        // 获取省市区
        getProvinceList() {
            let provinceList = provinceOption,
                newarr = [];
            provinceList.map((item) => {
                item.value = item.name;
                item.label = item.name;
                if (item.children && item.children.length) {
                    item.children.map((city) => {
                        city.value = city.name;
                        city.label = city.name;
                        delete city.children;
                    })
                }
                newarr.push(item);
            })
            this.optionsCity = newarr;
        },
        // 选中省市区
        ChangeCity(value) {
            this.$emit('handleChangeCity', value);
        },

    },
}

</script>
