<template>
  <div>
    <el-dialog title="图片剪裁" :visible.sync="dialogVisiblex" :close-on-press-escape="false" :close-on-click-modal="false"
      append-to-body width="1000px" :before-close="beforeClose">
      <div class="cropper-content f-c-a">
        <div class="cropper" style="text-align:center"
          :style="{ width: cropBoxWidth + 'px', height: cropBoxHeight + 'px' }">
          <vueCropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType"
            :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixedBox="option.fixedBox"
            :fixed="option.fixed" :fixedNumber="option.fixedNumber" :canMove="option.canMove"
            :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox"
            :infoTrue="option.infoTrue" :full="option.full" :enlarge="option.enlarge" :mode="option.mode"
            @realTime="realTime" @cropMoving="cropMoving">
          </vueCropper>
        </div>
        <!--预览效果图-->
        <div class="cropper-preview" style="display: none;">
          <!-- <div class="cropper-preview"> -->
          <div class="text">预览：</div>
          <div class="show-preview" :style="previewStyle">
            <div :style="previews.div" class="preview">
              <img :src="previews.url" :style="previews.img">
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="finish">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { AfterCompletingClippingMixin } from '@/mixins/BeforeUploadImageMixin';
export default {
  mixins: [AfterCompletingClippingMixin],
  props: {
    identifiLocation: {
      type: Array,
      defalut: []
    },
    originalWidth: {
      type: Number
    },
    originalHeight: {
      type: Number
    },
    uploadFileName: {
      type: String
    },
    // 无需主体识别 avatarUnidentify--头像上传不识别  HomePageUnidentify--首页不识别图片上传
    //   productLibraryUnidentify--产品库
    type: {
      type: String,
      defalut: ''
    },
    formdataParma: {
      type: Object,
      defalut: () => {
        return {}
      }
    },
    // 主体识别类型判断 productLibrary--产品库
    ImageSearchtype: {
      type: String
    }
  },
  data() {
    return {
      cropBoxWidth: 500,//裁剪框宽度
      cropBoxHeight: 500,//裁剪框高度
      p3: 0,//缩放比例
      dialogVisiblex: false,
      loading: false,
      randerCropBox: true,//重新渲染裁剪框
      option: {
        img: '', // 裁剪图片的地址 url 地址, base64, blob
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式 jpeg, png, webp
        info: false, // 裁剪框的大小信息
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: false, // 是否默认生成截图框
        autoCropWidth: 0, // 默认生成截图框宽度
        autoCropHeight: 0, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain' // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      previews: {},
      cropperMove: false,//默认不可移动
      previewStyle: {},
      // -------------
      cropType: '',//裁剪类型
      previewX: 0,
      previewY: 0,
      x1: 0,
      y1: 0,
      // -------------
      success: () => { } // 回调方法
    }
  },
  // 重新生成裁剪框，在页面更新时触发
  updated() {
    let that = this;
    if (!that.type && !that.cropperMove) {
      that.$refs.cropper.goAutoCrop();// 重新生成裁剪框
      that.$nextTick(() => {
        let x1 = +that.identifiLocation[0],//x1
          x2 = +that.identifiLocation[1],//x2
          y1 = +that.identifiLocation[2],//y1
          y2 = +that.identifiLocation[3],//y2
          { originalWidth, originalHeight, cropBoxWidth, cropBoxHeight, p3 } = that,
          p1 = cropBoxWidth / cropBoxHeight,//p1=裁剪框宽度/高度
          p2 = originalWidth / originalHeight,//p2=图片原始宽度/高度
          scaleWidth = 0,//等比缩放后的宽度
          scaleHeight = 0,//等比缩放后的高度
          halfWidthOffset = 0,//一半的宽度偏移量
          halfHeightOffset = 0,//一半的高度偏移量
          finshScanWidth = 0,//最后生成的裁剪宽度
          finshScanHeight = 0;//最后生成的裁剪高度
        if (p1 > p2) {//左右留白 ----裁剪高度和图片高度相等
          p3 = parseFloat(cropBoxHeight / originalHeight).toFixed(6);
          x1 = p3 * x1;
          x2 = p3 * x2;
          y1 = p3 * y1;
          y2 = p3 * y2;
          scaleWidth = Math.ceil(p3 * originalWidth);
          scaleHeight = Math.ceil(p3 * originalHeight);
          halfWidthOffset = Math.ceil((cropBoxWidth - scaleWidth) / 2);
          finshScanWidth = x2 - x1;
          finshScanHeight = y2 - y1;
          that.$refs.cropper.cropOffsertX = x1 + halfWidthOffset;// x1
          that.$refs.cropper.cropOffsertY = y1;// y1
          that.$refs.cropper.cropW = finshScanWidth;// 裁剪框宽
          that.$refs.cropper.cropH = finshScanHeight;// 裁剪框高
          // -------------
          // that.cropType = 'leftRight';
          // that.previewX = (scaleWidth - finshScanWidth) / 2;
          // that.previewY = y1
        } else if (p1 < p2) {//上下留白
          p3 = parseFloat(cropBoxWidth / originalWidth).toFixed(6);
          x1 = p3 * x1;
          x2 = p3 * x2;
          y1 = p3 * y1;
          y2 = p3 * y2;
          scaleHeight = Math.ceil(p3 * originalHeight);
          halfHeightOffset = Math.ceil((cropBoxHeight - scaleHeight) / 2);
          finshScanWidth = x2 - x1;
          finshScanHeight = y2 - y1;
          that.$refs.cropper.cropOffsertX = x1;// x1
          that.$refs.cropper.cropOffsertY = y1 + halfHeightOffset;// y1
          that.$refs.cropper.cropW = finshScanWidth;// 裁剪框宽
          that.$refs.cropper.cropH = finshScanHeight;// 裁剪框高
          // =====
          // that.cropType = 'topBottom';
          // that.x1 = x1;
          // that.y1 = y1;
          // that.previewX = x1 + halfHeightOffset;
          // that.previewY = y1 / 2
        } else {//正方形
          p3 = parseFloat(cropBoxHeight / originalHeight).toFixed(6);
          x1 = p3 * x1;
          x2 = p3 * x2;
          y1 = p3 * y1;
          y2 = p3 * y2;
          scaleWidth = Math.ceil(p3 * originalWidth);
          finshScanWidth = x2 - x1;
          finshScanHeight = y2 - y1;
          that.$refs.cropper.cropOffsertX = x1;// x1
          that.$refs.cropper.cropOffsertY = y1;// y1
          that.$refs.cropper.cropW = finshScanWidth;// 裁剪框宽
          that.$refs.cropper.cropH = finshScanHeight;// 裁剪框高
          // ====
          // that.cropType = 'center';
        }
      });
    }
  },
  created() {
  },
  methods: {
    // Clipping frame
    clippingFrameChange() {
    },
    showModal(obj) {
      if (obj.img) {
        this.option.img = obj.img
      }
      //裁剪生成图片的质量
      if (obj.outputSize) {
        this.option.outputSize = obj.outputSize
      } else {
        this.option.outputSize = 1
      }
      //裁剪生成图片的格式
      if (obj.outputType) {
        this.option.outputType = obj.outputType
      } else {
        this.option.outputType = 'jpg'
      }
      //裁剪框的大小信息
      if (obj.info) {
        this.option.info = obj.info
      } else {
        this.option.info = true
      }
      //图片是否允许滚轮缩放
      if (obj.canScale) {
        this.option.canScale = obj.canScale
      } else {
        this.option.canScale = false
      }
      //是否默认生成截图框
      if (obj.autoCrop) {
        this.option.autoCrop = obj.autoCrop
      } else {
        this.option.autoCrop = true
      }
      //默认生成截图框宽度
      if (obj.autoCropWidth) {
        this.option.autoCropWidth = obj.autoCropWidth
      } else {
        this.option.autoCropWidth = 0
      }
      //默认生成截图框高度
      if (obj.autoCropHeight) {
        this.option.autoCropHeight = obj.autoCropHeight
      } else {
        this.option.autoCropHeight = 0
      }
      //固定截图框大小 不允许改变
      if (obj.fixedBox) {
        this.option.fixedBox = obj.fixedBox
      } else {
        this.option.fixedBox = false
      }
      //是否开启截图框宽高固定比例
      if (obj.fixed) {
        this.option.fixed = obj.fixed
      } else {
        this.option.fixed = false
      }
      //截图框的宽高比例
      if (obj.fixedNumber) {
        this.option.fixedNumber = obj.fixedNumber
      } else {
        this.option.fixedNumber = [this.option.autoCropWidth, this.option.autoCropHeight]
      }
      //上传图片是否可以移动
      if (obj.canMove) {
        this.option.canMove = obj.canMove
      } else {
        this.option.canMove = false
      }
      //截图框能否拖动
      if (obj.canMoveBox) {
        this.option.canMoveBox = obj.canMoveBox
      } else {
        this.option.canMoveBox = false
      }
      //上传图片按照原始比例渲染
      if (obj.original) {
        this.option.original = obj.original
      } else {
        this.option.original = false
      }
      //截图框是否被限制在图片里面
      if (obj.centerBox) {
        this.option.centerBox = obj.centerBox
      } else {
        this.option.centerBox = true
      }
      //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      if (obj.infoTrue) {
        this.option.infoTrue = obj.infoTrue
      } else {
        this.option.infoTrue = true
      }
      //是否输出原图比例的截图
      if (obj.full) {
        this.option.full = obj.full
      } else {
        this.option.full = true
      }
      //图片根据截图框输出比例倍数
      if (obj.enlarge) {
        this.option.enlarge = obj.enlarge
      } else {
        this.option.enlarge = '1'
      }
      //图片默认渲染方式
      if (obj.mode) {
        this.option.mode = obj.mode
      } else {
        this.option.mode = 'contain'
      }
      if (obj.success) {
        this.success = obj.success
      } else {
        this.success = () => {
        }
      }
      this.dialogVisiblex = true //初始化完成弹出图片裁剪框
    },
    //  图片实时预览
    realTime(data) {
      var previews = data,
        that = this;
      //   { previewX, previewY, cropType, x1, y1 } = that;
      // let transformChange = previews.img.transform.split('('),
      //   scaleValue = transformChange[1].split(')')[0],
      //   transformXY = transformChange[2].split(')')[0].split(','),
      //   xx = Number(transformXY[0].slice(0, -2)) * scaleValue * scaleValue,
      //   yy = Number(transformXY[1].slice(0, -2)) * scaleValue * scaleValue,
      //   transformX = 0,
      //   transformY = 0,
      //   transformZ = Number(transformXY[2].slice(0, -2));

      // if (cropType == 'leftRight') {
      //   if (previewX < previewY) {
      //     transformX = Number(transformXY[0].slice(0, -2)) + previewX
      //     transformY = Number(transformXY[1].slice(0, -2)) - previewY
      //   } else {
      //     transformX = Number(transformXY[0].slice(0, -2)) - xx
      //     transformY = Number(transformXY[1].slice(0, -2)) - yy
      //   }
      // }
      // if (cropType == 'topBottom') {
      //   if (x1 < y1) {
      //     transformX = Number(transformXY[0].slice(0, -2)) + previewX
      //     transformY = Number(transformXY[1].slice(0, -2)) + previewY
      //   } else if (x1 > y1) {
      //     transformX = Number(transformXY[0].slice(0, -2)) + (xx / 2)
      //     transformY = Number(transformXY[1].slice(0, -2)) - (y1 * scaleValue)
      //   } else {
      //     transformX = Number(transformXY[0].slice(0, -2)) - (xx * 2)
      //     transformY = Number(transformXY[1].slice(0, -2)) - (yy * 2)
      //   }
      // }
      // 初始化预览框
      // if (!that.type && !that.cropperMove && that.cropType != 'center') {
      // previews.img.transform = `scale(${scaleValue})translate3d(${transformX}px, ${transformY}px, ${transformZ}px)rotateZ(0deg)`
      // }

      that.previewStyle = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        // zoom: 200 / parseInt(previews.w)
      }
      that.previews = data;
    },
    // 截图框移动回调函数
    cropMoving() {
      setTimeout(() => {
        this.cropperMove = true
      }, 500)
    },
    finish() {
      // 获取截图的数据
      let that = this;
      if (that.type == 'avatarUnidentify') {//头像上传
        let userId = parseInt(that.$store.state.userId),
          token = that.$getStorage('token');
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' });
          const formdata = new FormData();
          formdata.append("file", file)
          formdata.append("userId", userId)
          formdata.append("token", token)
          that.$emit('uploadOssFile', formdata)
        })
      } else if (that.type == 'HomePageUnidentify') {//首页
        console.log('HomePageUnidentify')
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' }),
            formdataParma = that.formdataParma;

          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              that.$store.commit('saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", formdataParma.num)
              formdata.append("productType", that.$getStorage('productType') ?? 0)
              formdata.append("enterpriseId", formdataParma.enterpriseId)
              that.$emit('getUploadImageSearchList', formdata);
              that.$setStorage('previewsformdataParma', formdataParma);
            }
          );

          // that.$store.commit('saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", formdataParma.num)
          // formdata.append("productType", that.$getStorage('productType') ?? 0)
          // formdata.append("enterpriseId", formdataParma.enterpriseId)
          // that.$emit('getUploadImageSearchList', formdata);
          // that.$setStorage('previewsformdataParma', formdataParma);
        })
      } else if (that.type == 'productLibraryUnidentify') {  // 产品库no主体识别
        console.log('产品库no主体识别')
        // that.AfterCompletingClippingMixin();
        that.$refs.cropper.getCropBlob(data => {
          var url = URL.createObjectURL(data);
          this.$setStorage('ProductLibrary_previewsUrl', url)
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' }),
            formdataParma = that.formdataParma;
          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              // newfile  = res;
              that.$store.commit('ProductLibrary_saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", formdataParma.num)
              formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
              formdata.append("lastModifyUserId", formdataParma.lastModifyUserId)
              formdata.append("token", formdataParma.token)
              that.$emit('getUploadImageSearchList', formdata);
              that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);

            }
          );
        })
      } else if (that.ImageSearchtype == 'productLibrary') { //产品库图像搜索打开主体识别
        console.log('主体productLibrary')
        that.$refs.cropper.getCropBlob(data => {
          var url = URL.createObjectURL(data);
          this.$setStorage('ProductLibrary_previewsUrl', url)
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' }),
            formdataParma = that.formdataParma;
          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              // newfile  = res;
              that.$store.commit('ProductLibrary_saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", 20)
              formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
              formdata.append("lastModifyUserId", parseInt(that.$store.state.userId))
              formdata.append("token", that.$getStorage('token'))
              that.$emit('getUploadImageSearchList', formdata);
              that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);

            }
          );

          // that.$store.commit('ProductLibrary_saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", 20)
          // formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
          // formdata.append("lastModifyUserId", parseInt(that.$store.state.userId))
          // formdata.append("token", that.$getStorage('token'))
          // that.$emit('getUploadImageSearchList', formdata);
          // that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);
        })
      } else if (that.ImageSearchtype == 'EnterpriseRelatedProducts') { //企业相关的商品搜索打开主体识别
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' });

          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              that.$store.commit('saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", 20)
              formdata.append("productType", that.$getStorage('productType') ?? 0)
              formdata.append("enterpriseId", that.$getStorage('EnterpriseRelatedId') ?? '')
              that.$emit('getUploadImageSearchList', formdata, that.$getStorage('EnterpriseRelatedId'));
            }
          );

          // that.$store.commit('saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", 20)
          // formdata.append("productType", that.$getStorage('productType') ?? 0)
          // formdata.append("enterpriseId", that.$getStorage('EnterpriseRelatedId') ?? '')
          // that.$emit('getUploadImageSearchList', formdata, that.$getStorage('EnterpriseRelatedId'));
        })
      } else if (that.ImageSearchtype == 'EnterpriseSearch') { //搜索页搜索企业商品
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' });

          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              that.$store.commit('saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", 20)
              formdata.append("productType", that.$getStorage('productType') ?? 0)
              formdata.append("enterpriseId", that.$getStorage('EnterpriseRelatedId') ?? '')
              that.$emit('getUploadImageSearchList', formdata, that.$getStorage('EnterpriseRelatedId'));
            }
          );

          // that.$store.commit('saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", 20)
          // formdata.append("productType", that.$getStorage('productType') ?? 0)
          // formdata.append("enterpriseId", that.$getStorage('EnterpriseRelatedId') ?? '')
          // that.$emit('getUploadImageSearchList', formdata, that.$getStorage('EnterpriseRelatedId'));
        })
      } else if (that.ImageSearchtype == 'uploadProducts') {
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' });
          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              const formdata = new FormData();
              formdata.append("file", res);
              that.$emit('getUpImagefile', formdata);
              that.dialogVisiblex = false;
            }
          );
          // const formdata = new FormData();
          // formdata.append("file", file);
          // that.$emit('getUpImagefile', formdata);
          // that.dialogVisiblex = false;

        })
      } else if (that.type == 'ProductLibrarySimilaritysearch') {
        that.$refs.cropper.getCropBlob(data => {
          var url = URL.createObjectURL(data);
          that.$setStorage('ProductLibrary_previewsUrl', url);
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' }),
            formdataParma = that.formdataParma;

          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              that.$store.commit('ProductLibrary_saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", formdataParma.num)
              formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
              formdata.append("lastModifyUserId", formdataParma.lastModifyUserId)
              formdata.append("token", formdataParma.token)
              that.$emit('getUploadImageSearchList', formdata);
              that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);
            }
          );

          // that.$store.commit('ProductLibrary_saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", formdataParma.num)
          // formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
          // formdata.append("lastModifyUserId", formdataParma.lastModifyUserId)
          // formdata.append("token", formdataParma.token)
          // that.$emit('getUploadImageSearchList', formdata);
          // that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);
        })
      } else if (that.ImageSearchtype == 'ProductLibrarySimilaritysearch') {
        that.$refs.cropper.getCropBlob(data => {
          var url = URL.createObjectURL(data);
          that.$setStorage('ProductLibrary_previewsUrl', url);
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' }),
            formdataParma = that.formdataParma;
          let Promisefile = that.AfterCompletingClippingMixin(file);

          Promisefile.then(
            (res) => {
              that.$store.commit('ProductLibrary_saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res)
              formdata.append("num", 20)
              formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
              formdata.append("lastModifyUserId", that.$store.state.userId)
              formdata.append("token", that.$getStorage('token'));
              that.$emit('getUploadImageSearchList', formdata);
              that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);

            }
          );
          // that.$store.commit('ProductLibrary_saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file)
          // formdata.append("num", 20)
          // formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0)
          // formdata.append("lastModifyUserId", that.$store.state.userId)
          // formdata.append("token", that.$getStorage('token'));
          // that.$emit('getUploadImageSearchList', formdata);
          // that.$setStorage('ProductLibrary_previewsformdataParma', formdataParma);
        })
      } else {//首页主体识别
        that.$refs.cropper.getCropBlob(data => {
          let file = new window.File([data], that.uploadFileName, { type: 'image/jpeg' });
          let Promisefile = that.AfterCompletingClippingMixin(file);
          Promisefile.then(
            (res) => {
              that.$store.commit('saveFileData', res);
              const formdata = new FormData();
              formdata.append("file", res);
              formdata.append("num", 20);
              formdata.append("productType", that.$getStorage('productType') ?? 0);
              formdata.append("enterpriseId", '');
              that.$emit('getUploadImageSearchList', formdata);

            }
          );

          // that.$store.commit('saveFileData', file);
          // const formdata = new FormData();
          // formdata.append("file", file);
          // formdata.append("num", 20);
          // formdata.append("productType", that.$getStorage('productType') ?? 0);
          // formdata.append("enterpriseId", '');
          // that.$emit('getUploadImageSearchList', formdata);
        })
      }

    },
    // 取消
    cancel() {
      let that = this;
      that.dialogVisiblex = false;
      that.$emit('close', false);
    },
    // 点击右上角关闭
    beforeClose() {
      this.cancel()
    }
  }
}
</script>
<style lang="less" scoped>
.real_info_class {
  .el-checkbox__input .el-checkbox__inner {
    border-radius: 0;
  }
}

.file-image {
  width: 320px;
  height: 320px;
  font-size: 14px;
  border: 1px solid #cccccc;
  margin: 15px 0;
}


.cropper-preview {
  align-self: flex-start;

  .text {
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
  }

}
</style>
