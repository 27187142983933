<template>
    <el-select :style="{ width: widthVal ,height: heightVal}" :disabled="disabled" v-model="SelectModelValue" @change="SelectChange">
        <el-option v-for="(i, idx) of Option" :label="i.label" :value="i.value" :key="idx"></el-option>
    </el-select>
</template>

<script>
export default {
    name: '',
    props: ['selectModel', 'Option', 'type', 'widthVal', 'heightVal', 'disabled'],
    watch: {
        "selectModel": {
            deep: true,
            handler: function (newVal) {
                this.$nextTick(() => {
                    this.SelectModelValue = newVal
                })
            }
        },
    },
    data() {
        return {
            SelectModelValue: this.selectModel
        }
    },
    methods: {
        SelectChange(value) {
            let that = this,
                type = that.type;
            switch (type) {
                case 'ValuationCurrency'://货币类型
                    that.$emit('selectValuationCurrency', value)
                    break;
                case 'FreightCurrency'://运费货币
                    that.$emit('selectFreightCurrency', value)
                    break;
                case 'nameNumber'://搜索名称编号
                    that.$emit('nameNumberSelectOptionVal', value)
                    break;
                case 'amountChangeType'://资产变化：
                    that.$emit('selectAmountChangeType', value)
                    break;
                case 'stockCtype'://资产变化：
                    that.$emit('selectStockCtype', value)
                    break;
            }
        }
    },
}
</script>
