import router from '@/router';
import { Message, MessageBox } from 'element-ui';
import { http } from './https'

const common = {
    message(message, type = 'info', duration = 1000) {
        return Message({
            message,
            type,
            duration
        })
    },
    // 此功能暂不开放
    TemporarilyClosed() {
        this.message('此功能暂不开放', 'warning', 2000)
    },
    /* 限制输入框的输入字数
    *  title 标题
    *  maxlengthValue 最大字数
    */
    inputMaxLength(title, maxlengthValue) {
        if (title.length >= maxlengthValue) {
            return this.message(`输入字数不能超过${maxlengthValue}字！`, 'warning', 2000)
        }
    },
    // 压缩确认框
    zipConfim(zipResultf) {
        MessageBox.confirm('压缩后图片仍大于4M，是否继续压缩?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'del-model',
            type: 'warning'
        }).then(() => {
            this.commonZipPic(zipResultf)
        }).catch(() => { });
    },
    // 图片通用压缩提示
    commonZipPic(file) {
        const _this = this
        return new Promise((resolve, reject) => {
            const image = new Image()
            let resultBlob = ''
            image.src = URL.createObjectURL(file)
            image.onload = () => {
                // 调用方法获取blob格式，方法写在下边
                resultBlob = _this.compressUpload(image, file)
                const fs = new File([resultBlob], file.name, {
                    type: file.type
                })
                let zipResultf = fs;
                
                if (fs.size / 1048576 > 4.1000) {
                    return _this.commonZipPic(zipResultf)
                }
                console.log('fs.size',fs.size / 1048576)
                resolve(fs)
            }
            image.onerror = () => {
                reject()
            }
        })
    },
    // 查看图片尺寸大小 宽高任意一边高于4096需要启动压缩
    checkImgSize(file) {
        const _this = this;
        return new Promise(function (resolve, reject) {
            const URL = window.URL || window.webkitURL
            const img = new Image();
            img.onload = function () {
                if (img.width < 100 || img.height < 100) {
                    _this.message('图片过小，请选择长宽均超过100像素（最小100x100）的图片进行上传；', 'error', 2500)
                    reject()
                } else if (img.width >= 4096 || img.height >= 4096) {
                    const newfile = _this.compressUpload(img, file)
                    resolve(newfile)
                } else {
                    resolve(file)
                }
            }
            img.src = URL.createObjectURL(file)
            img.onerror = () => {
                reject()
            }
        })
    },
    /* 图片压缩方法-canvas压缩 */
    compressUpload(image, file) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { width, height } = image;
        let compressWidth = width * 0.75;//等比例压缩
        let compressHeight = height * 0.75;
        if (compressWidth >= 4096 || compressHeight >= 4096) {
            compressWidth *= 0.75
            compressHeight *= 0.75
        }
        canvas.width = compressWidth
        canvas.height = compressHeight
        // console.log(width, height, 'orgin', canvas.width, canvas.height, 'canvas.height')
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        // 进行最小压缩0.1
        const compressData = canvas.toDataURL(file.type || 'image/jpeg', 0.8)
        // 压缩后调用方法进行base64转Blob，方法写在下边
        const blobImg = this.dataURItoBlob(compressData)
        return blobImg
    },
    /* base64转Blob对象 */
    dataURItoBlob(data) {
        let byteString
        if (data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(data.split(',')[1])
        } else {
            byteString = unescape(data.split(',')[1])
        }
        const mimeString = data
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: mimeString })
    },
    // 过滤掉数组中undefined的值
    filterUndefined(arr) {
        return arr.filter(res => {
            if (res != 'undefined') {
                return res
            }
        })
    },
    filterStringUndefined(str) {
        return str.split(' ').filter(i => i !== 'undefined')
    },
    // 获取年月日时分秒
    timeToStr() {
        let timestamp = Date.now();
        var date = new Date(timestamp);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
    },
    timeToYMD() {
        let timestamp = Date.now();
        var date = new Date(timestamp);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        return Y + M + D;
    },
    // 将数组值取出
    checkCityArr(arr) {
        return arr.join(' ')
    },
    // 将字符串切割成数组--将地区切割成数组
    splitCityArr(str) {
        if (str.indexOf("其他地区") != -1) {
            let otherArr = [];
            otherArr.push(str.replace(/\s*/g, ""));
            return otherArr
        } else {
            let strArr = str.split(' '),
                strNewArr = strArr.slice(0, 2),
                mergeStr = '';
            for (let i = 2; i < strArr.length; i++) {
                if (!strArr[i] && strArr[i + 1] && strArr[i - 1]) {
                    strArr[i] = '  '
                }
                mergeStr += strArr[i]
            }
            strNewArr.push(mergeStr)
            return strNewArr
        }
    },
    // 深拷贝
    deepClone(obj) {
        var target = {};
        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object') {
                    target[key] = this.deepClone(obj[key]);
                } else {
                    target[key] = obj[key];
                }
            }
        }
        return target;
    },
    //返回首页清除路由栈 
    ClearRouteStack() {
        let routeHistory = history.length - 2;
        router.go(-routeHistory);
        router.replace('/home', () => {
            window.location.reload()
        })
    },
    // 刷新当前页 that.$common.RefreshCurrent();
    RefreshCurrent(delaye = 1000) {
        setTimeout(() => {
            router.go(0)
        }, delaye)
    },
    // 延迟返回上一页 that.$common.DelayedBack();
    DelayedBack(delaye = 1200) {
        setTimeout(() => {
            router.go(-1)
        }, delaye)
    },
    // 延迟返回上两页 that.$common.DelayedBackTow();
    DelayedBackTow(delaye = 1200) {
        setTimeout(() => {
            router.go(-2)
        }, delaye)
    }
}
const storage = {
    setStorage(key, value) {
        return localStorage.setItem(key, JSON.stringify(value))
    },
    getStorage(key) {
        return localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key)) : null;
    },
    removeStroage(key) {
        return localStorage.removeItem(key)
    }
}

const CommonRequests = {
    // 获取关联的单据模板备注信息
    getEnterpriseOrderTemplate(templateType) {
        return http.getEnterpriseOrderTemplate({
            lastModifyUserId: storage.getStorage('userId'),
            token: storage.getStorage('token'),
            templateType: templateType//单据类型SA(销售单)、RG（退货单）、RS（供应商退回单）
        })
    },
    //  获取请求ip地址接口
    getIpAddress() {
        return http.getIpAddress({
            userId: storage.getStorage('userId'),
            token: storage.getStorage('token'),
        })
    },
}

export { common, storage, CommonRequests }