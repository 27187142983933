import { phoneRule, passRule, EnterpriseTelephoneRule } from '@/utils/vallidate';


// 必填且失去焦点提示
function RequiredBlurTip(message) {
    return [
        { required: true, message, trigger: 'blur' }
    ]
}
// 必填且内容改变时提示
function RequiredChangeTip(message) {
    return [
        { required: true, message, trigger: "change" }
    ]
}
// f非必填且失去焦点提示
function NoRequiredBlurValidatorTip(rule) {
    return [{ validator: rule, trigger: 'blur' }]
}
// 必填且失去焦点且有规则提示
function RequiredBlurValidatorTip(message, validatorRule = phoneRule) {
    return [
        { required: true, message, trigger: "blur", validator: validatorRule }
    ]
}
// 职业
let JodResume = {
    position: RequiredBlurTip('请输入名字'),
    salary: RequiredBlurTip('请选择薪资范围'),
    postContent: RequiredBlurTip('请输入岗位详情'),
    workAddress: RequiredBlurTip('请输入地址'),
    contact: RequiredBlurTip('请输入联系人'),
    contactValue: RequiredBlurTip('请输入联系方式'),
}

// 帖子
let LookMaterialsPostResume = {
    productType: RequiredChangeTip('请选择产品类型'),
    postHead: RequiredBlurTip('请输入帖子标题'),
    postContent: RequiredBlurTip('请输入帖子内容'),
    contact: RequiredBlurTip('请输入联系人'),
    contactValue: RequiredBlurTip('请输入联系方式'),
    expirationDays: RequiredBlurTip('请选择有效期'),
}
let postResume = {
    postHead: RequiredBlurTip('请输入帖子标题'),
    postContent: RequiredBlurTip('请输入帖子内容')
}
let mediaResume = {
    mediumName: RequiredBlurTip('请输入媒体名字'),
    contact: RequiredBlurTip('请输入联系人'),
    contactPhone: RequiredBlurTip('请输入联系电话'),
    city: RequiredChangeTip('请输选择所在城市'),
    address: RequiredBlurTip('请输入详细地址'),
    introduction: RequiredBlurTip('请输入简介'),
    homeImgFile: RequiredChangeTip("请上传主页图片"),
    certificateImg: RequiredChangeTip("请上传证书图片"),
}
// 填写简历
let fillResume = {
    name: RequiredBlurTip('请输入名字'),
    position: RequiredBlurTip('请输入职位名称'),
    jobHunt: RequiredBlurTip('请选择在职状态'),
    workLocation: RequiredChangeTip('请输选择工作城市'),
    age: RequiredBlurTip('请输入年龄'),
    sex: RequiredBlurTip('请选择性别'),
    salary: RequiredBlurTip('请选择薪资范围'),
    selfEvaluation: RequiredBlurTip('请输入个人评价'),
    contactValue: RequiredBlurTip('请输入联系方式'),
    saveState: RequiredBlurTip('请选择是否开放简历'),
}
// 填写工作经历
let workExperience = {
    corporateName: RequiredBlurTip('请输入公司名称'),
    position: RequiredBlurTip('请输入职位名称'),
    worktime: RequiredBlurTip('请选择在职时间'),
    workLocation: RequiredChangeTip('请选择所在城市'),
    salary: RequiredChangeTip('请选择薪资范围'),
}
// 填写教育经历
let educationExperience = {
    schoolName: RequiredBlurTip('请输入学校名称'),
    position: RequiredBlurTip('请输入职位名称'),
    degree: RequiredBlurTip('请选择学历'),
    speciality: RequiredChangeTip('请输入专业名称'),
}

// 用户名密码登陆规则
let countRules = {
    phone: NoRequiredBlurValidatorTip(phoneRule),
    password: NoRequiredBlurValidatorTip(passRule),
}
// 短信登陆规则--提示输入手机号
let shortMsgRules = {
    phone: NoRequiredBlurValidatorTip(phoneRule),
}
let transferadminRules = {
    phone: NoRequiredBlurValidatorTip(phoneRule),
}

// 企业入驻、编辑企业信息
let EditEnterpriseInfoRules = {
    enterpriseName: RequiredBlurTip("请输入企业名称"),
    legalPerson: RequiredBlurTip("请输入法人名称"),
    contact: RequiredBlurTip("请输入联系人"),
    contactTelephone: RequiredBlurValidatorTip("请输入联系电话", EnterpriseTelephoneRule),
    enterpriseTelephone: RequiredBlurValidatorTip("请输入企业电话", EnterpriseTelephoneRule),
    city: RequiredChangeTip("请输入所在城市"),
    address: RequiredBlurTip("请输入详细地址"),
    introduction: RequiredBlurTip("请输入企业简介"),
    enterpriseImg: RequiredChangeTip("请上传企业主页图片"),
    permit: RequiredChangeTip("请上传营业执照"),
    // legalPersonIdf: RequiredChangeTip("请上传法人身份证正面"),
    // legalPersonIdb: RequiredChangeTip("请上传法人身份证反面"),
}
// 新增客户
let EnterPriseRules = {
    customerName: RequiredBlurTip("请输入客户名称"),
    contact: RequiredBlurTip("请输入联系人"),
    customerPhone: RequiredBlurTip("请输入11位联系电话"),
    customerCity: RequiredChangeTip("请选择所在城市"),
    customerAddress: RequiredBlurTip("请输入详细地址")
}
// 修改库存
let InventoryFormRules = {
    ProductUnitPrice: RequiredBlurTip("请输入产品单价"),
    stockChangeNumber: RequiredBlurTip("请输入变更数量"),
    amountChangeNumber: RequiredBlurTip("请输入变更金额"),
    valuationCurrency: RequiredBlurTip("")
}
// 产品库添加
let ProductLibraryAddRules = {
    productName: RequiredBlurTip("请输入产品名称"),
    img1Url: RequiredChangeTip("请上传产品主图")
}
// 单据模板
let DocTemplateEditRules = {
    enterpriseName: RequiredBlurTip("请输入企业抬头")
}
// 添加订单
let AddOrderRules = {
    customerName: RequiredBlurTip("请输入客户名称"),
    contactPhone: RequiredBlurTip("请输入联系电话"),
    customerCity: RequiredChangeTip("请输入所在地区"),
    customerAddress: RequiredBlurTip("请输入详细地址"),
    orderActiveDetailsList: RequiredBlurTip("请选择商品")
}
// 工作牌
let workCardRulesNoPhone = {
    name: RequiredBlurTip("请输入姓名！"),

}
// 工作牌手机号验证
let workCardRulesHasPhone = {
    name: RequiredBlurTip("请输入姓名！"),
}

// 发布职位
let positionFormRules = {
    position: RequiredBlurTip("请输入职位名称"),
    salary1: RequiredBlurTip("请输入薪资"),
    salary2: RequiredBlurTip("请输入薪资"),
    postContent: RequiredBlurTip("请输入岗位职责"),

}



export {
    countRules,
    shortMsgRules,
    EditEnterpriseInfoRules,
    EnterPriseRules,
    InventoryFormRules,
    ProductLibraryAddRules,
    DocTemplateEditRules,
    AddOrderRules,
    workCardRulesNoPhone,
    workCardRulesHasPhone,
    positionFormRules,
    fillResume,
    workExperience,
    educationExperience,
    mediaResume,
    postResume,
    LookMaterialsPostResume,
    transferadminRules,
    JodResume
}