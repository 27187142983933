<template>
  <div class="header">
    <div class="container f-c-b">
      <div class="left f-c-c" @click="ToHome">
        <img v-if="logoShow" style="width: 40px;height: 40px;" src="@/assets/logo2.png" alt="">
        <img v-if="logoShow" style="height: 30px;width: 65px;margin-left: 10px;"
          src="@/assets/shiliao.png" alt="">
      </div>
      <div class="right f-c-e">
        <!-- 首页 供应 社区 -->
        <ul class="nav-ul f-c">
          <li style="width: 33%;" v-for="(item, index) of navList" :key="index" @click="navTo(index)">
            {{ item.title }}
          </li>
        </ul>
        <!-- <ul v-else class="nav-ul f-c">
          <li style="width: 33%;" v-for="(item, index) of navList1" :key="index" @click="navTo(index)">
            {{ item.title }}
          </li>
        </ul> -->
        <!-- 登录后显示 -->
        <div class="login-msg f-c" v-if="$getStorage('token')">
          <el-popover placement="bottom-end" width="320" trigger="hover">
            <div class="popoverBox f-c">
              <img style="width: 50px;height: 50px;border-radius: 50%;margin-right: 10px;" :src="userAvatarUrl" alt="">
              <div style="font-size: 16px;">
                <div style="color: #333;">{{ userName }}</div>
                <div style="margin-top: 10px;color: #999;font-size: 14px;">{{ userPhone }}</div>
              </div>
              <div
                style="margin-left: 40px;width: 120px;height: 40px;background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);color: #FFF;line-height: 40px;text-align: center;border-radius: 10px;cursor: pointer;"
                @click="signOut">
                <i class="el-icon-switch-button" style="margin-right: 5px;"></i>安全退出
              </div>
            </div>
            <div style="margin-top: 30px;height: 80px;" class="f-c-b">
              <router-link to="/user/collect" class="f-cl-c" style="cursor: pointer;">
                <img style="width: 35px;height: 35px;margin-bottom: 10px;" src="@/assets/collect.png" alt="">
                <div>我的收藏</div>
                <!-- style="color: #333;font-size: 16px;" -->
              </router-link>
              <router-link to="/user/footprint" class="f-cl-c" style="cursor: pointer;">
                <img style="width: 35px;height: 35px;margin-bottom: 10px;" src="@/assets/footprint.png" alt="">
                <div>足迹</div>
              </router-link>
              <router-link to="/user" class="f-cl-c" style="cursor: pointer;">
                <img style="width: 35px;height: 35px;margin-bottom: 10px;" src="@/assets/personal.png" alt="">
                <div>个人信息</div>
              </router-link>
              <router-link to="/user/setting" class="f-cl-c" style="cursor: pointer;">
                <img style="width: 35px;height: 35px;margin-bottom: 10px;" src="@/assets/setting.png" alt="">
                <div>设置</div>
              </router-link>
              <router-link to="/user/find" class="f-cl-c" style="cursor: pointer;">
                <img style="width: 35px;height: 35px;margin-bottom: 10px;" src="@/assets/find.png" alt="">
                <div>我在找料</div>
              </router-link>
            </div>
            <router-link slot="reference" to="/user" class="user-info f-c">
              <el-image :src="userAvatarUrl"></el-image>
            </router-link>
          </el-popover>
        </div>
        <!-- 登录按钮 -->
        <div v-else class="login-tip" @click="showLoginBox">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'NavHeader',
  inject: ['reload'],
  data() {
    return {
      navList: [{
        title: '首页',
      }, {
        title: '供应',
      },
      {
        title: '社区',
      }],
      // navList2: [{
      //   title: '首页',
      // },
      // {
      //   title: '社区',
      // }],
      logoShow: true,
    }
  },
  computed: {
    ...mapState({
      userName: 'userName',
      userAvatarUrl: 'userAvatarUrl',
      userId: 'userId',
      userPhone: 'userPhone',
      spType: 'spType'
    }),
  },
  created() {
    let pathName = this.$route.path;
    if(pathName == '/home/index' || pathName == '/TalentRecruitmentIndex' || pathName == '/TalentRecruitmentList' || pathName == '/PersonalCenter' || pathName == '/ResumeDetails' || pathName == '/ControlsResume'){
      this.logoShow = false;
    }
  },
  methods: {
    ToHome() {
      this.$router.push('/home')
      this.$removeStorage('pageLayer')
      this.$removeStorage('pageLayerBack')
    },
    showLoginBox() {
      this.$parent.showLoginBox()
    },
    navTo(index) {
      // if (this.spType == 'S') {
        switch (index) {
          case 0:
            this.$route.path != '/home/index' ? this.$common.ClearRouteStack() : ''
            break;
          case 1:
            this.$getStorage('token') ? this.$router.push('/SupplyIndex') : this.$parent.showLoginBox();
            this.$removeStorage('fetchDataPage')
            this.$removeStorage('fetchDataSize')
            break;
          case 2:
            // this.$getStorage('token') ? this.$router.push('/CommunityIndex') : this.$parent.showLoginBox();
            // this.$removeStorage('fetchDataPage')
            // this.$removeStorage('fetchDataSize')
            this.$common.TemporarilyClosed();
            break;
        }
      // } 
      // else if (this.spType == 'P') {
      //   switch (index) {
      //     case 0:
      //       this.$route.path != '/home/index' ? this.$common.ClearRouteStack() : ''
      //       break;
      //     case 1:
      //       this.$getStorage('token') ? this.$router.push('/CommunityIndex') : this.$parent.showLoginBox();
      //       this.$removeStorage('fetchDataPage')
      //       this.$removeStorage('fetchDataSize')
      //       // this.$common.TemporarilyClosed()
      //       break;
      //   }
      // }

      this.$removeStorage('handleScanValue')
      this.$removeStorage('pageLayerBack')
      this.$removeStorage('listHandleScan')
      this.$removeStorage('JoinEnterpriseSearchValue')
      this.$removeStorage('ProductLibrarySearchValue')
    },
    // 退出
    signOut() {
      let userId = parseInt(this.userId),
        token = this.$getStorage('token'),
        spType = this.$getStorage('spType'),
        reflush = this.$getStorage('reflush')
      this.$http.logout({ userId, token }).then(res => {
        if (res.code == 200) {
          if (this.$route.name == 'index') {
            this.reload()
          }
          this.$router.push('/home')
          localStorage.clear();
          this.$setStorage('spType', spType);
          this.$setStorage('reflush', reflush);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/mixins.less';
@navHeight: 50px;

.header {
  padding-left: 320px;
  width: 100%;
  height: @navHeight;
  background-color: #F8F8F8;
  position: fixed; // 固定在最顶端
  top: 0;
  z-index: 11;

  .container {
    width: calc(100vw - 640px);
    height: 100%;

    .left {}

    .right {
      width: 1280px;

      .nav-ul {
        cursor: pointer;
        width: 300px;

        .nav-ul:hover {
          color: red !important;
        }

        li {
          width: 70px;
          list-style: none;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          text-align: center;
          color: #666666;
          // color: red;

        }
        li:hover {
          color: #0363FA !important;
        }
      }

      .login-msg {
        // margin-right: 30px;

        // .collect {
        //   height: 40px;
        //   line-height: 40px;
        //   font-size: 16px;
        //   display: block;
        //   padding: 0 15px;
        // }
        .el-popover {
          padding-right: 0;
        }

        .popoverBox {
          border: 1px solid red;
        }


        .user-info {
          margin-left: 60px;

          .el-image {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // .sign-out {
        //   font-size: 14px;
        //   color: #000;
        //   text-decoration: underline;
        //   margin-left: 5px;

        //   &:hover {
        //     cursor: pointer;
        //   }
        // }
      }
    }

    .login-tip {
      height: 30px;
      width: 64px;
      color: #FFFFFF;
      background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      margin-left: 20px;
    }

    .login-tip:hover {
      cursor: pointer;
    }
  }
}

.header:focus {
  outline: none;
}
</style>