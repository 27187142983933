<template>
    <el-input type="textarea" show-word-limit :maxlength="maxlengthValue" :autosize="{ minRows: 3 }"
        :placeholder="placeholderValue" v-model="modelvalue" @keyup.enter.native="keyupEnter" @input="valueChange"
        :disabled="disabledValue" style="white-space: pre-wrap;word-break: break-all;">
    </el-input>
</template>

<script>
import { VerificationFormatMethods } from '@/mixins/VerificationFormat'
export default {
    name: 'TextArea',
    mixins: [VerificationFormatMethods],
    /**
     * type的值：
     * orderNote--订单备注
     * modifyNote--操作备注
     */
    props: {
        placeholderValue: {
            type: String,
            default: '暂无数据'
        },
        modelValue: {},
        type: {
            type: String,
            default: ''
        },
        disabledValue: {
            type: Boolean,
            default: false
        },
        maxlengthValue: {
            // type: Number,
            default: 100
        },
        // 文本框是否限制输入
        TextAreaisLimit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modelvalue: this.modelValue
        }
    },
    watch: {
        modelValue() {
            this.modelvalue = this.modelValue
        }
    },
    methods: {
        valueChange(value) {
            if (this.TextAreaisLimit) {
                value = this.VerificationFormat(value, 3)
                this.modelvalue = value
            }
            let data = {
                value: value,
                type: this.type
            }
            this.$emit('getTextAreaValue', data)
        },
        keyupEnter() {
            this.$common.inputMaxLength(this.modelvalue, this.maxlength);
        },
    },
}

</script>

