import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'
import SupplyChainManagement from './SupplyChainManagement';
import Community from './Community';
import Product from './Product';
import User from './User'

Vue.use(Router)

const orginnalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
    return orginnalPush.call(this, location).catch(err => err)
}

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            redirect: '/home/index', //默认重定向到index页，即包含头尾和内容的页面
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/Home'),
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('@/pages/Login'),
                },
            ]
        },
        {
            path: '/home',
            name: 'home',
            redirect: '/home/index',
            component: () => import('@/pages/Home'),
            children: [
                ...Product,
                ...SupplyChainManagement,
                ...Community,
                {
                    path: '/home/index',
                    name: 'index',
                    component: () => import('@/pages/Index'),
                    meta: {
                        showFooter: true,
                        keepAlive: true
                    }
                }, {
                    path: '/search',
                    name: 'search',
                    component: () => import('@/pages/Search'),
                    meta: {
                        showFooter: true,
                        // keepAlive: true
                    }
                },
                {
                    path: '/searchFirm',
                    name: 'searchFirm',
                    component: () => import('@/pages/SearchFirm'),
                    meta: { 
                        showFooter: true
                     }
                },
                {
                    path: '/Favorites',
                    name: 'Favorites',
                    component: () => import('@/pages/other/Favorites'),
                    meta: { showFooter: true }
                }, {
                    path: '/footmark',
                    name: 'footmark',
                    component: () => import('@/pages/other/footmark'),
                    meta: { showFooter: true }
                },
                {
                    path: '/ColourLibrary',
                    name: 'ColourLibrary',
                    component: () => import('@/pages/ColourLibrary'),
                    meta: { showFooter: true }
                },
                // Color search
                {
                    path: '/MoreGoods',
                    name: 'MoreGoods',
                    component: () => import('@/pages/MoreGoods'),
                    meta: { showFooter: true }
                },
                {
                    path: '/MoreEnterprise',
                    name: 'MoreEnterprise',
                    component: () => import('@/pages/MoreEnterprise'),
                    meta: { showFooter: true }
                },
                {
                    path: '/Coloursearch',
                    name: 'Coloursearch',
                    component: () => import('@/pages/Coloursearch'),
                    meta: { showFooter: true }
                },
                {
                    path: '/SupplierInfoIndex',
                    name: 'SupplierInfoIndex',
                    component: () => import('@/pages/SupplierInfoIndex'),
                    meta: { showFooter: true }
                },
                {
                    path: '*',
                    name: 'notFound',
                    component: () => import('@/pages/NotFound'),
                    meta: { showFooter: true }
                },
            ]
        },
        ...User,
    ],
    mode: 'history'
})