/**
 * Vuex-mutations
 */
import { storage } from '@/utils/common.js'

export default {
  // 保存相似度产品id列表
  keepProductIdSimilarityList(state, value) {
    state.productIdSimilarityList = value
    storage.setStorage('keepProductIdSimilarityList', value)
  },
  // 保存用户名
  saveUserName(state, value) {
    state.userName = value
    storage.setStorage('userName', value)
  },
  // 保存用户Id
  saveUserId(state, value) {
    state.userId = value
    storage.setStorage('userId', value)
  },
  // 保存用户token
  saveToken(state, value) {
    state.token = value
    storage.setStorage('token', value)
  },
  // 保存用户头像
  saveUserAvatarUrl(state, value) {
    state.userAvatarUrl = value
    storage.setStorage('userAvatarUrl', value)
  },
  // 保存身份
  spType(state, value) {
    state.spType = value
    storage.setStorage('spType', value)
  },
  // 保存身份
  InvestigateFlag(state, value) {
    state.InvestigateFlag = value
    storage.setStorage('InvestigateFlag', value)
  },
  // 保存用户手机号码
  saveUserPhone(state, value) {
    state.userPhone = value
    storage.setStorage('userPhone', value)
  },
  // 产品详情数据
  ProductLibraryForm(state, value) {
    state.ProductLibraryForm = value
    storage.setStorage('ProductLibraryForm', value)
  },
  // 添加相似产品
  addSimilarProductForm(state, value) {
    state.addSimilarProductForm = value
    storage.setStorage('addSimilarProductForm', value)
  },
  // 修改库存中的产品信息
  productInventoryBaseInfo(state, value) {
    state.productInventoryBaseInfo = value
    storage.setStorage('productInventoryBaseInfo', value)
  },
  // 保存上次增加产品的产品类型
  newProductType(state, value) {
    state.newProductType = value
    storage.setStorage('newProductType', value)
  },
  // 保存相似度企业产品id列表
  keepEnterpriseProductIdSimilarityList(state, value) {
    state.keepEnterpriseProductIdSimilarityList = value
    storage.setStorage('keepEnterpriseProductIdSimilarityList', value)
  },
  saveFileData(state, value) {
    state.saveFileData = value
  },
  ProductLibrary_saveFileData(state, value) {
    state.ProductLibrary_saveFileData = value
  },
  productType(state, value) {
    state.productType = value
  },
}