// 个人信息
export default [{
    path: '/user',
    name: 'user',
    redirect: '/User',
    component: () => import('@/pages/Home'),
    children: [
        {
            path: '/User',
            name: 'index',
            component: () => import('@/pages/user/Index'),
            redirect: '/User/userBaseInfo',
            children: [
                // {
                //     path: '/User/userBaseInfo',
                //     name: '个人中心',
                //     iconClass: 'el-icon-s-custom',
                //     component: () => import('@/pages/user/UserBaseInfo'),
                // }, 
                // {
                //     path: '/User/order',
                //     name: '设置',
                //     iconClass: 'el-icon-s-custom',
                //     component: () => import('@/pages/user/order'),
                // },
                {
                    path: '/User/userBaseInfo',
                    name: 'userBaseInfo',
                    // iconClass: 'el-icon-s-custom',
                    component: () => import('@/pages/user/UserBaseInfo'),
                }, 
                {
                    path: '/User/collect',
                    name: 'collect',
                    // iconClass: 'el-icon-s-custom',
                    component: () => import('@/pages/user/collect'),
                }, {
                    path: '/User/footprint',
                    name: 'footprint',
                    // iconClass: 'el-icon-s-custom',
                    component: () => import('@/pages/user/footprint'),
                }, {
                    path: '/User/setting',
                    name: 'setting',
                    iconClass: 'el-icon-s-custom',
                    component: () => import('@/pages/user/setting'),
                }, {
                    path: '/User/find',
                    name: 'find',
                    // iconClass: 'el-icon-s-custom',
                    component: () => import('@/pages/user/find'),
                },
            ]
        },

    ]
}]