// 订单状态
const orderState = {
    Ready: () => { return '未发货' },
    Deliver: () => { return '已发货' },
    Delivery: () => { return '已收货' },
    Completed: () => { return '已完成' },
    Canceled: () => { return '已取消' }
}
// 订单不同状态不同表现颜色
const orderTextColor = {
    Ready: () => { return '#C49423' },
    Deliver: () => { return '#0363FA' },  // 已发货
    Delivery: () => { return '#2F54EB' },
    Completed: () => { return '#0FBA13' },  // 已付款
    Canceled: () => { return '#EF2C2C' }  // 已取消
}
const orderBackgroundColor = {
    Ready: () => { return '#F9F4E9' },
    Deliver: () => { return '#EFF4FA' },  // 已发货
    Delivery: () => { return '#EAEEFD' },
    Completed: () => { return '#E2F7E2' },  // 已付款
    Canceled: () => { return '#FDE9E9' }  // 已取消
}

const orderClass = {
    // 获取订单状态文本
    getOrderState(state) {
        return orderState[state] ? orderState[state]() : ''
    },
    // 获取
    getOrderTextColor(state) {
        return orderTextColor[state] ? orderTextColor[state]() : ''
    },
    // 获取
    getorderBackgroundColor(state) {
        return orderBackgroundColor[state] ? orderBackgroundColor[state]() : ''
    }
}
export {
    orderClass
}