<template>
    <div class="imageUpload" :style="imageUploadTrends">
        <div class="upload-box">
            <el-upload class="upload-demo" drag action="#" :http-request="uploadImage" :before-upload="beforeUpload"
                :limit="1" :show-file-list="false" :on-change="handleChange">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <div class="f-c switch">
                <div>主体识别</div>
                <el-switch v-model="switchState" @change="changeSubjectIdentification">
                </el-switch>
            </div>
            <el-button class="icon-close" @click="close"><i class="el-icon-close"></i></el-button>
        </div>
        <ImageCropper ref="iscropper" :identifiLocation="identifiLocation" :originalWidth="originalWidth"
            :originalHeight="originalHeight" :uploadFileName="uploadFileName"
            @getUploadImageSearchList="getUploadImageSearchList" @close="close" :type="ImageCropperType"
            :formdataParma="formdataParma" :ImageSearchtype="ImageSearchtype">
        </ImageCropper>
    </div>
</template>

<script>
import { beforeUploadMixin } from '@/mixins/BeforeUploadImageMixin';

export default {
    name: 'ImageUpload',
    /**
     * ImageSearchtype--区分是企业搜索还是非企业搜索
     * 产品库中调用接口：aLiEnterpriseProductImageSearch
     * 非产品库调用接口：aLiProductImageSearch
     */
    props: ['ImageSearchtype', 'imageUploadTrends'],
    components: {
        ImageCropper: require('@/components/ImageCropper.vue').default
    },
    mixins: [beforeUploadMixin],
    data() {
        return {
            switchState: false,//是否打开主体识别
            num: 20,//搜索条目数
            productType: 1,//产品类型
            enterpriseId: '',//企业id
            loaclServerPathIdentifi: '',//主体识别本地图片路径
            identifiLocation: [],//主体识别的坐标
            originalWidth: 0,//图片原始宽度
            originalHeight: 0,//图片原始高度
            uploadFileName: '',//上传图片的名字
            resultBlob: {},//压缩后的blob
            ImageCropperType: '',//裁剪框类型
            formdataParma: {},
        }
    },
    created() {
        // console.log('ImageSearchtype',this.ImageSearchtype)
    },
    methods: {
        // 是否打开主体识别
        changeSubjectIdentification(e) {
            this.switchState = e
        },
        // 获取上传图片的信息
        getImageInfo(file) {
            let reader = new FileReader(),
                that = this;
            reader.onload = function (e) {
                let txt = e.target.result
                let img = document.createElement("img")
                img.src = txt
                img.onload = function () {
                    that.originalWidth = img.width
                    that.originalHeight = img.height
                }
            };
            reader.readAsDataURL(file);
        },
        // 文件上传前的钩子函数 可以对文件类型大小进行筛选  
        // async beforeUpload(file) {
        //     let that = this;
        //     if (that.switchState) {
        //         that.getImageInfo(file)
        //     }
        //     const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'],// 只能上传jpg和png和jpeg的图片
        //         isImage = imageTypes.includes(file.type),
        //         isLt4M = file.size / 1024 / 1024 > 4;// 大小限制为4M  
        //     console.log(file, isLt4M)
        //     await new Promise(function (resolve, reject) {//分辨率最大4096*4096
        //         let width = 4096,
        //             height = 4096,
        //             _URL = window.URL || window.webkitURL,
        //             image = new Image();

        //         image.onload = function () {
        //             const valid = image.width <= width && image.height <= height;
        //             console.log(image.width, image.height)
        //             valid ? resolve() : reject()
        //         }
        //         image.src = _URL.createObjectURL(file)
        //     }).then(
        //         () => {
        //             return file
        //         },
        //         () => {
        //             that.$common.message('上传的图片最大分辨率不超过4096*4096', 'error')
        //             return Promise.reject(false)
        //         }
        //     );

        //     if (!isImage) {
        //         that.close();
        //         return that.$common.message('上传图片只能是 JPG、JPEG、PNG 格式!', 'error', 1500)
        //     }
        //     if (isLt4M) {
        //         return await this.$common.commonZipPic(file)
        //     }
        // },

        // 获取上传图片搜索的列表
        getUploadImageSearchList(formdata, enterpriseRelatedId) {
            let that = this;
            if (that.ImageSearchtype == 'productLibrary') {
                that.$http.aLiEnterpriseProductImageSearch(formdata).then(res => {
                    if (res.code == 200) {
                        let EnterpriseProductIdList = [],
                            newArr = [];//创建空数组
                        for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
                            const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
                            newArr.push({//给新数组里添加内容
                                'productId': obj.productId,
                                'score': obj.score
                            })
                        }
                        // EnterpriseProductIdList = newArr;
                        this.$store.commit('keepEnterpriseProductIdSimilarityList', newArr);
                        res.data.forEach(item => {
                            EnterpriseProductIdList.push(item.productId)
                        })
                        that.$setStorage('ProductLibrary_queryList', EnterpriseProductIdList);
                        that.$emit('closeUploadBox', false)
                        console.log('EnterpriseProductIdList',EnterpriseProductIdList)
                        this.$router.push({
                            name: 'productLibrarySearchResult',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    list: EnterpriseProductIdList
                                }))
                            }
                        })
                    }
                })
            } else if (that.ImageSearchtype == 'ImageSearch' || that.ImageSearchtype == 'EnterpriseSearch') {
                that.$http.aLiProductImageSearch(formdata).then(res => {
                    that.$setStorage('previewsformdataParma', JSON.parse(JSON.stringify(Object.fromEntries(formdata.entries()))));
                    if (res.code == 200) {
                        let productIdList = [],
                            newArr = [];//创建空数组
                        for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
                            const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
                            newArr.push({//给新数组里添加内容
                                'productId': obj.productId,
                                'score': obj.score
                            })
                        }
                        this.$store.commit('keepProductIdSimilarityList', newArr);
                        res.data.forEach(item => {
                            productIdList.push(item.productId)
                        })
                        that.$emit('closeUploadBox', false)
                        that.$emit('getpreviewsUrl', this.loaclServerPathIdentifi);
                        that.$emit('getUploadImageSearchList', productIdList);
                    }
                })
            }else if(that.ImageSearchtype == 'ProductLibrarySimilaritysearch'){
                that.$http.aLiEnterpriseProductImageSearch(formdata).then(res => {
                    that.$setStorage('previewsformdataParma', JSON.parse(JSON.stringify(Object.fromEntries(formdata.entries()))));
                    if (res.code == 200) {
                        let productIdList = [],
                            newArr = [];//创建空数组
                        for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
                            const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
                            newArr.push({//给新数组里添加内容
                                'productId': obj.productId,
                                'score': obj.score
                            })
                        }
                        this.$store.commit('keepEnterpriseProductIdSimilarityList', newArr);
                        res.data.forEach(item => {
                            productIdList.push(item.productId)
                        })
                        this.$emit('getQueryList', productIdList);
                        this.$emit('closeUploadBox', false);
                        
                    }
                })
            } else {
                that.$http.aLiProductImageSearch(formdata).then(res => {
                    that.$setStorage('previewsformdataParma', JSON.parse(JSON.stringify(Object.fromEntries(formdata.entries()))));
                    if (res.code == 200) {
                        let productIdList = [],
                            newArr = [];//创建空数组
                        for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
                            const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
                            newArr.push({//给新数组里添加内容
                                'productId': obj.productId,
                                'score': obj.score
                            })
                        }
                        this.$store.commit('keepProductIdSimilarityList', newArr);
                        res.data.forEach(item => {
                            productIdList.push(item.productId)
                        })
                        that.$emit('closeUploadBox', false)
                        this.$setStorage('queryList', productIdList);
                        this.$router.push({
                            name: 'search',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'image',
                                    // list: productIdList,
                                    enterpriseId: enterpriseRelatedId,
                                }))
                            }
                        })
                    }
                })
            }
        },

        //上传文件   
        async uploadImage(params) {
            let that = this;
            const formdata = new FormData();
            that.uploadFileName = params.file.name
            if (that.switchState) {//主体识别
                formdata.append("file", params.file)
                // 生成主体识别坐标后再打开裁剪框
                await that.$http.aLiYunImageIdentification(formdata).then(res => {
                    if (res.code == 200) {
                        this.identifiLocation = res.data.split(',')
                    }
                })
                this.cropper()
            } else {
                if (that.ImageSearchtype == 'productLibrary') {
                    this.ImageCropperType = 'productLibraryUnidentify';
                    this.formdataParma = {
                        num: that.num,//搜索条目数
                        lastModifyUserId: that.$store.state.userId,
                        token: that.$getStorage('token')
                    }
                    this.cropper()
                } else if (that.ImageSearchtype == 'EnterpriseRelatedProducts') {
                    this.ImageCropperType = 'EnterpriseRelatedProducts';
                    this.cropper()
                }
                else if (that.ImageSearchtype == 'EnterpriseSearch') {
                    this.ImageCropperType = 'EnterpriseSearch';
                    this.cropper()
                }else if(that.ImageSearchtype == 'ProductLibrarySimilaritysearch'){
                    that.ImageCropperType = 'ProductLibrarySimilaritysearch';
                    that.formdataParma = {
                        num: that.num,//搜索条目数
                        lastModifyUserId: that.$store.state.userId,
                        token: that.$getStorage('token')
                    }
                    that.cropper()
                }
                else {
                    this.ImageCropperType = 'HomePageUnidentify';
                    this.formdataParma = {
                        num: that.num,//搜索条目数
                        productType: that.productType,//产品类型
                        enterpriseId: that.enterpriseId//企业id
                    }
                    this.cropper()
                }
            }
        },

        // 主体识别生成裁剪框
        cropper() {
            this.$refs.iscropper.showModal({
                img: this.loaclServerPathIdentifi, // 裁剪图片的地址
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                info: true, // 裁剪框的大小信息
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 0, // 默认生成截图框宽度
                autoCropHeight: 0, // 默认生成截图框高度
                fixedNumber: [1, 1], // 截图框的宽高比例
                fixedBox: false, // 固定截图框大小 不允许改变 
                fixed: false, // 是否开启截图框宽高固定比例
                canMove: false, // 上传图片是否可以移动
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                full: true, // 是否输出原图比例的截图
                enlarge: '1', // 图片根据截图框输出比例倍数
                mode: 'contain', // 图片默认渲染方式
                success: res => {
                    this.unimgurl = res.img
                    this.imageUrl = URL.createObjectURL(res.img)
                }
            })
        },

        // 获取图片本地服务器路径
        handleChange(file) {
            this.loaclServerPathIdentifi = URL.createObjectURL(file.raw)
            this.$setStorage('previewsUrl', this.loaclServerPathIdentifi);
        },
        // 关闭图片上传盒子
        close() {
            this.switchState = false
            this.$emit('closeUploadBox', false)
        }
    }

}

</script>
<style lang="less" scoped>
.imageUpload {
    z-index: 10;
    border-radius: 10px;
    width: 450px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 8px 10px rgb(157, 157, 157, .4);

    .upload-box {
        position: relative;

        .upload-demo {
            /deep/ .el-upload {
                width: 100% !important;
            }

            /deep/ .el-upload-dragger {
                width: 100%;
                height: 110px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .el-icon-upload {
                    margin: 0 auto 20px;

                }

            }
        }

        .switch {
            margin-top: 15px;

            .el-switch {
                margin-left: 15px;
            }
        }

        /deep/ .icon-close {
            position: absolute;
            top: -5px;
            right: -5px;
            padding: 5px;
            z-index: 1;
            border: none;
            font-size: 18px;
        }
    }

    .choose {
        margin: 20px auto 0;
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #409EFF;
        color: #fff;
        border-radius: 20px;

        .el-upload__text {
            color: #fff;
        }
    }
}
</style>