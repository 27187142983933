<template>
  <div class="home">
    <el-backtop :bottom="150"><i class="el-icon-caret-top"></i></el-backtop>
    <NavHeader :key="new Date().getTime()" />
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive" class="container" :style="{minHeight:clientHeight+ 'px'}"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="container" :style="{minHeight:clientHeight+ 'px'}"></router-view>
    <NavFooter v-show="$route.meta.showFooter" />
    <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
</div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavFooter from '@/components/NavFooter.vue';

export default {
  name: 'home',
  inject: ['reload'],
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      loginBoxVisible: false,
      clientHeight: 0
    }
  },
  created(){
    this.clientHeight = this.getClientHeight() - 60 //剪去底部本身的高度
  },
  methods: {
    // 登陆框开始--------
    showLoginBox() {
      this.loginBoxVisible = true
    },
    CloseLoginBox() {
      this.loginBoxVisible = false
    },
    // 登陆框结束--------
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      else {
       clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      return clientHeight;
    }

  }
}
</script>
<style lang="less" scoped>
.home {
  min-width: 1280px;
  background: #FFFFFF;

  .container {
    width: 1280px;
    padding: 80px 0 30px;
    margin: auto;
    box-sizing: border-box;
    overflow: hidden;
  }
}
</style>
