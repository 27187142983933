// 商品
export default [{
    path: '/productDetail',
    name: 'ProductDetail',
    component: () => import('@/pages/ProductManagement/ProductDetail'),
    meta: {
        showFooter: true,
    }
}, {
    path: '/MoreProductList',//更多产品列表
    name: 'MoreProductList',
    component: () => import('@/pages/ProductManagement/MoreProductList'),
    meta: { showFooter: true }
}]