<template>
  <div v-if="showFooter" class="NavFooter f-c-c">
    <!-- <span>版权所有 © 广州市彩鸿皮革有限公司 2023 All Rights Reserved.</span> -->
    <span>版权所有 © 深圳市识料神科技有限公司 2023 All Rights Reserved.</span>
    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2023095696号</a>
    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702005677"
      style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
      <img src="../assets/ga.png" style="float:left;margin-right: 5px;" />粤公网安备 44030702005677号</a>
  </div>
</template>
<script>
export default {
  name: 'NavFooter',
  data() {
    return {
      showFooter: true
    }
  }
}
</script>

<style lang="less">
.NavFooter {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
  text-align: center;
  color: #FFFFFF;

  a {
    color: #FFFFFF;
    margin-left: 60px;
  }

  a:hover {
    color: red;
  }
}
</style>