<template>
    <!-- 职业 -->
    <Dialog title="请选择您的具体职业" width="690px" :visible="visibleValue" class="jodDialog">
        <div class="tip">便于为您提供最佳服务</div>
        <div v-if="$getStorage('spType') == 'S'" class="f-w">
            <div :class="Jod == item.id ? 'select' : 'btns'" v-for="(item, index) in supplyJodList" :key="index"
                @click="Jod = item.id">{{ item.name }}</div>
        </div>
        <div v-else class="f-w">
            <div :class="Jod == item.id ? 'select' : 'btns'" v-for="(item, index) in purchaseList" :key="index"
                @click="Jod = item.id">{{ item.name }}</div>
        </div>
        <div v-if="$getStorage('InvestigateFlag') == 'I'" class="f-c invitationCode">
            <div class="title">邀请码</div>
            <el-input type="number" max="4" v-model="invitationCode" placeholder="请输入邀请码" @input="inputChange"></el-input>
        </div>
        <div class="verifyBtn" @click="verifyJod()">确定</div>
    </Dialog>
</template>
<script>
export default {
    name: '',
    props: {
        jodBoxVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visibleValue: this.jodBoxVisible,
            invitationCode: '',  // 邀请码
            Jod: '',
            supplyJodList: [  // 供应类职业
                {
                    id: 10001,
                    value: '材料工厂',
                    name: '材料工厂'
                }, {
                    id: 10002,
                    value: '贸易档口',
                    name: '贸易档口'
                }, {
                    id: 10003,
                    value: '成品鞋、包厂',
                    name: '成品鞋、包厂'
                }, {
                    id: 10004,
                    value: '皮、化工原料供应商',
                    name: '皮、化工原料供应商'
                }, {
                    id: 10005,
                    value: '机械配件供应商',
                    name: '机械配件供应商'
                }, {
                    id: 10099,
                    value: '其它',
                    name: '其它 '
                }
            ],
            purchaseList: [  // 采购职业
                {
                    id: 1,
                    value: '设计师',
                    name: '设计师'
                }, {
                    id: 2,
                    value: '采购员',
                    name: '采购员'
                }, {
                    id: 3,
                    value: '开发员',
                    name: '开发员'
                }, {
                    id: 4,
                    value: '板师',
                    name: '板师'
                }, {
                    id: 5,
                    value: '人力资源招聘',
                    name: '人力资源招聘'
                }, {
                    id: 6,
                    value: '企业主',
                    name: '企业主'
                }, {
                    id: 7,
                    value: '商协会',
                    name: '商协会'
                }, {
                    id: 8,
                    value: '杂志媒体',
                    name: '杂志媒体'
                }, {
                    id: 9,
                    value: '贸易公司',
                    name: '贸易公司'
                }, {
                    id: 999,
                    value: '其它',
                    name: '其它'
                }
            ],
        }
    },
    watch: {
        jodBoxVisible() {
            this.visibleValue = this.jodBoxVisible
        }
    },
    created() {
        this.jodVisibleVisible = this.$getStorage('InvestigateFlag') == 'Y' || this.$getStorage('InvestigateFlag') == 'I' ? true : false;
    },
    methods: {
        // 调查确认
        verifyJod() {
            if (this.Jod) {
                this.$http.addUserSurveys({
                    typeNote: this.Jod,
                    iuserCode: this.invitationCode,
                    spType: localStorage.getItem('spType') != null ? JSON.parse(localStorage.getItem('spType')) : null,
                    userId: parseInt(this.$store.state.userId),
                }).then(res => {
                    if (res.code == 200) {
                        this.$emit('CloseJobBox')
                        this.$setStorage('reflush', true)
                        this.$setStorage('InvestigateFlag', 'N');
                    }
                })
            } else {
                this.$common.message('请选择您的职业', 'error')
            }

        },
        inputChange(e){
            let val = e.slice(0, 4);
            this.invitationCode= val;

        }
    }
}

</script>
<style lang="less">
.jodDialog {
    .el-dialog__headerbtn {
        display: none;
    }

    span {
        color: #333333;
        font-size: 30px;
    }

    .tip {
        font-size: 16px;
        color: #333333;
    }

    .btns {
        padding: 16px 30px;
        font-size: 16px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
        margin-right: 20px;
        margin-top: 20px;
        cursor: pointer;
    }

    .select {
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        color: #FFF;
        padding: 16px 30px;
        font-size: 16px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
        margin-right: 20px;
        margin-top: 20px;
        cursor: pointer;
    }

    .invitationCode {
        margin-top: 20px;

        .title {
            font-size: 16px;
            margin-right: 30px;
        }

        .el-input {
            width: 400px !important;
            height: 60px;
            border: none;
        }

        .el-input__inner {
            height: 60px;
            border: none;
            box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
            padding: 10px 20px;
        }
    }

    .verifyBtn {
        margin: 20px auto 0;
        border-radius: 10px;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        height: 60px;
        width: 160px;
        text-align: center;
        line-height: 60px;
        color: #FFF;
        font-size: 16px;
        cursor: pointer;
    }
}
</style>