<template>
    <div class="f-c">
        <CodeInput :modelValue="code" @getCodeValue="getCodeValue"></CodeInput>
        <el-button type="text" @click="getCode(type)" :disabled="disabled" class="get-code">{{ isGetCode ? count
            + 's后重新获取' : (isHandleGetCode ? '重新获取验证码' : '获取验证码') }}</el-button>
    </div>
</template>

<script>
export default {
    props: ['phone', 'type'],
    data() {
        return {
            code: '',
            count: '',//倒计时时间
            timer: null,//计时器
            disabled: false,//是否禁用获取验证码
            isGetCode: false,//0-未获取 1-已获取
            isHandleGetCode: false,//是否点击获取验证码
        }
    },
    methods: {
        getCodeValue(value) {
            this.$setStorage('codeValue', { code: value, type: this.type })
        },
        // 获取验证码
        getCode(form) {
            let that = this,
                data = null, url = '';
            if (form == 'shorMsgForm' || form == 'forgotPwdForm') {
                data = { phone: that.phone }
                url = 'user/getPhoneCode'
            } else if (form == 'registerForm') {
                data = { phone: that.phone }
                url = 'user/getRegCode'
            } else if (form == 'transferadminForm') {
                data = { userId: parseInt(that.$store.state.userId) }
                url = 'enterprise/findReviseEnterpriseAdministratorCode'
            }
            that.$apiPost(url, data).then(res => {
                if (res.code == 200) {
                    that.$message({ message: '验证码发送成功！', type: 'success' })
                    that.countDown()
                } else {
                    that.$common.message(res.msg, 'error')
                }
            })
        },
        // 倒计时
        countDown() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.isGetCode = true;
                this.disabled = true
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.isGetCode = false;
                        this.disabled = false;
                        this.isHandleGetCode = true
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
    }
}
</script>
<style lang="less" scoped>
.get-code {
    width: 50%;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
</style>