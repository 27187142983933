<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true
      });
    },
  }
}
</script>


<style lang="less">
@import url('@/assets/css/reset.less');
@import url('@/assets/css/mixins.less');
@import url('./assets/css/editor.css');
@import url('./assets/css/flex.css');



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-dialog {
  min-width: 400px;
}

.backPreviousPage:hover {
  cursor: pointer;

}

.backPreviousPage {
  margin: 10px 0 15px;
}

.resultTips {
  color: red;
  font-size: 14px;
  margin: 10px 0 20px 10px;
  display: block;
}

.el-page-header {
  align-self: flex-start;
  margin-bottom: 20px;
}

// 城市地区选项下拉框高度
.el-cascader__dropdown {
  height: 350px !important;
  overflow-y: hidden;

  .el-cascader-panel {
    height: 350px !important;
  }
}

.el-cascader-menu__wrap {
  height: 100%;
}

// 城市地区单个选项高度
.el-cascader-node {
  height: 40px !important;
  line-height: 40px !important;
}

// 城市地区输入框宽度
.el-cascader--medium {
  width: 100% !important;
}


// input类型为number
//去掉后方箭头
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

//输入汉字光标偏移问题
/deep/.el-input__inner {
  line-height: 1px !important;
}

// 一个按钮
.One-btn {
  text-align: center;
  margin: 50px auto;

  button {
    width: 50%;
  }
}

// 两个按钮
.Two-btn {
  margin: 50px auto;

  button {
    width: 20% !important;
  }
}
</style>
