<template>
    <div class="has-img">
        <el-upload action="" :limit="1" :show-file-list="false" :disabled="disabled" :before-upload="beforeUpload"
            :http-request="httpRequest" :on-success="onSuccess" ref="upload" accept=".jpeg,.jpg,.png">
            <el-image v-if="imageUrl && imageUrl != 'D'" style="width: 130px; height: 130px" fit="contain" :src="imageUrl">
            </el-image>
            <div v-else class="no-img" style="position: relative;">
                <i class="el-icon-circle-plus"></i>
                <div style="position: absolute;bottom: 5px;color: #999999;font-size: 14px;">点击上传图片</div>
            </div>
        </el-upload>
        <i v-if="!disabled && imageUrl && imageUrl != 'D'" @click="deleteImg" class="delete-btn el-icon-close"></i>
    </div>
    <!-- function (file) { return uploadImg(file, imageDiff) } -->
</template>

<script>
import { beforeUploadMixin } from '@/mixins/BeforeUploadImageMixin';
export default {
    props: {
        imageUrl: {
            type: String,
            default: ''
        },
        imageDiff: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    mixins: [beforeUploadMixin],
    methods: {
        httpRequest(file) {
            this.uploadImg(file, this.imageDiff)
        },
        uploadImg(params, type) {
            let that = this;
            that.$emit('FormData', params, type)
            this.onSuccess();
        },
        onSuccess() {
            this.$refs["upload"].clearFiles();
        },
        deleteImg() {
            this.$emit('deleteUploadImage', this.imageDiff)
        },

    },
}

</script>
<style lang='less' scoped>
.has-img {
    position: relative;

    .delete-btn {
        position: absolute;
        left: 140px;
        top: -10px;
        border: 1px solid #333;
        border-radius: 50%;
        padding: 2px;
        color: #333;
        background: #fff;
    }

    .delete-btn:hover {
        background: red;
        border: 1px solid red;
        color: #fff;
    }
}

.no-img {
    width: 190px;
    height: 145px;
    border: 1px solid #E6E6E6 !important;
    border-radius: 10px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0675F3;
}
</style>
