<template>
    <el-dialog :visible="visibleValue" width="690px" :before-close="handleClose">
        <LoginBox></LoginBox>
    </el-dialog>
</template>

<script>
export default {
    name: '',
    props: {
        loginBoxVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visibleValue: this.loginBoxVisible,
            isJodVisible: ''
        }
    },
    watch: {
        loginBoxVisible() {
            this.visibleValue = this.loginBoxVisible
        }
    },
    methods: {
        handleClose() {
            this.$emit('CloseLoginBox')
            let that = this
                // pathName = that.$route.path,
                // InvestigateFlag = that.$getStorage('InvestigateFlag');
            if (that.$getStorage('token') != null) {
                // if (InvestigateFlag != 'N') {
                    that.$parent.reload();
                // }
            }

        },
    }
}

</script>
