<template>
    <el-input type="number" v-model.trim="modelvalue" :placeholder="placeholderText" clearable @input="handleInput"
        v-stopNumberMousewhell :maxlength="maxlength" title="" autocomplete="off"></el-input>
</template>

<script>
export default {
    name: 'DecimalInput',
    props: {
        placeholderText: {
            type: String,
            default: ''
        },
        modelValue: {},
        typeValue: {},
        // 前十位数字 + 一位小数点 + 两位保留数字
        maxlength: {
            default: 13
        },
        //输入框能输入的最大值，用来检验
        maxValue: {
            type: String,
            default: "9999999999.99"
        }
    },
    data() {
        return {
            modelvalue: this.modelValue
        }
    },
    watch: {
        modelValue() {
            if (this.typeValue == 'amountChangeNumber') {
                this.modelvalue = this.modelValue
            }
        },
    },
    methods: {
        handleInput() {
            let modelValue = this.modelvalue,
                firstNum = modelValue.substr(0, 1);
            // 默认值为0，直接输入大于零的数则自动去掉零
            modelValue = firstNum == 0 && !String(modelValue).includes(".") ? modelValue.substr(1) : modelValue;
            // 空格清空后从0开始输入并且带有小数点，则小数点前面补零
            modelValue = firstNum == "." ? "0" + modelValue : modelValue;
            // 如果值为空则默认为0
            modelValue == '' ? modelValue = 0 : modelValue;
            if (String(modelValue).includes(".")) {
                let numDian = modelValue.toString().split(".")[1].length
                if (numDian > 2) {
                    let dotIndex = modelValue.indexOf(".")
                    modelValue = modelValue.substring(0, dotIndex + 3)
                }
            }
            if (Number(modelValue) > Number(this.maxValue)) {
                const tipNumber = {
                    13: () => { return '10' },
                    8: () => { return '5' },
                    24: () => { return '21' }
                }
                let tip = tipNumber[this.maxlength] ? tipNumber[this.maxlength]() : ''
                this.$common.message(`不符合小数点前最大${tip}位，小数点后最大两位！`, 'warning', 2000)
                modelValue = ""
            }
            this.modelvalue = modelValue
            this.$emit('DecimalInputEvent', { value: modelValue, type: this.typeValue })
        }
    },
}

</script>
<style lang='less' scoped>
//输入汉字光标偏移问题
/deep/.el-input__inner {
    line-height: 1px !important;
}
</style>
