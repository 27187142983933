/**
 * Vuex-actions
 */
export default {
  saveUserName(context,username){
    // 通过context.commit去触发mutations中的同名方法
    // 然后通过mutations中的同名方法去修改state中的username的值
    context.commit('saveUserName', username);
  },

}