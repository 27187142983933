import Vue from 'vue';
import Vuex from 'vuex';
import { storage } from '@/utils/common.js'
import mutations from './mutations'
import actions from './action'
Vue.use(Vuex);

const state = {
    productIdSimilarityList: storage.getStorage('keepProductIdSimilarityList') != null ? storage.getStorage('keepProductIdSimilarityList') : [],
    userName: storage.getStorage('userName') != null ? storage.getStorage('userName') : '',
    userAvatarUrl: storage.getStorage('userAvatarUrl') != null ? storage.getStorage('userAvatarUrl') : '',
    spType: storage.getStorage('spType') != null ? storage.getStorage('spType') : '',
    userPhone: storage.getStorage('userPhone') != null ? storage.getStorage('userPhone') : '',
    userId: storage.getStorage('userId') != null ? storage.getStorage('userId') : '',
    token: storage.getStorage('token') != null ? storage.getStorage('token') : '',
    ProductLibraryForm: storage.getStorage('ProductLibraryForm') != null ? storage.getStorage('ProductLibraryForm') : '',
    addSimilarProductForm: storage.getStorage('addSimilarProductForm') != null ? storage.getStorage('addSimilarProductForm') : '',
    productInventoryBaseInfo: storage.getStorage('productInventoryBaseInfo') != null ? storage.getStorage('productInventoryBaseInfo') : '',
    keepEnterpriseProductIdSimilarityList: storage.getStorage('keepEnterpriseProductIdSimilarityList') != null ? storage.getStorage('keepEnterpriseProductIdSimilarityList') : [],
    saveFileData:'',
    ProductLibrary_saveFileData:'',
    productType:'',
};

const store = new Vuex.Store({
    state,
    mutations,
    actions
});

export default store;