import axios from 'axios'
// import dayjs from 'dayjs';
import router from '@/router/index'
import { Message } from 'element-ui'
import { showFullScreenLoading, tryHideFullScreenLoading, AllendLoading } from './loading'
// import { common } from './common.js'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASEURL + '/api/',
    timeout: 50000,
    retry: 3, //设置全局重试请求次数（最多重试几次请求）
    retryDelay: 1000, //设置全局请求间隔
})

// 是否正在刷新的标志
window.isRefreshing = false;
// 存储请求的数组
let cacheRequestArr = [];

// 将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]
function cacheRequestArrHandle(cb) {
    cacheRequestArr.push(cb);
}
// 数组中的请求得到新的token之后自执行，用新的token去重新发起请求
function afreshRequest(token) {
    cacheRequestArr.map(cb => cb(token));
    cacheRequestArr = [];
}

// 判断token是否即将过期
function isTokenExpired() {
    // let tokenDate = localStorage.getItem('tokenDate') != null ? localStorage.getItem('tokenDate') : null,
    //     expiresDate = parseInt(dayjs(tokenDate).add(2, "days").format("YYYYMMDD")),
    //     nowDate = parseInt(dayjs(new Date()).format("YYYYMMDD"));
    let timeUpdate = localStorage.getItem('timeUpdate'),
    nowDate = new Date().getTime(),
    oneDay = 86400000,
    // fiveDay = 432000000,
    sevonDay = 604800000;
    if ((nowDate - timeUpdate >= oneDay) && (nowDate - timeUpdate < sevonDay)) {
        return true
    }
    return false;
}
// token刷新
function refreshToken() {
    service.post('user/refreshToken', {
        token: localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')) : null,
        userId: localStorage.getItem('userId') != null ? JSON.parse(localStorage.getItem('userId')) : null,
        spType: localStorage.getItem('spType') != null ? JSON.parse(localStorage.getItem('spType')) : null,
    }).then(res => {
        if (res.data.code == 200) {
            localStorage.setItem('token', JSON.stringify(res.data.data.token));
            afreshRequest(res.data.data.token);
            let timeUpdate = new Date().getTime();// 获取当前的时间戳
            localStorage.setItem('timeUpdate', timeUpdate);
        }
    }).catch(err => {
        return Promise.reject(err)
    }).finally(() => {
        window.isRefreshing = false;
    })
    // return data
}

// 请求拦截器
service.interceptors.request.use((config) => {
    // 执行loading
    showFullScreenLoading();
    // 让每个请求携带token
    config.headers['token'] = localStorage.getItem('token') != null ? 'Bearer' + JSON.parse(localStorage.getItem('token')) : null;

    // 判断token是否即将过期，且不是请求刷新token的接口
    if (isTokenExpired() && config.url !== 'user/refreshToken') {
        // 所有的请求来了，先判断是否正在刷新token，
        // 如果不是，将刷新token标志置为true并请求刷新token.
        // 如果是，则先将请求缓存到数组中
        // 等到刷新完token后再次重新请求之前缓存的请求接口即可
        if (!window.isRefreshing) {
            // 标志改为true，表示正在刷新
            window.isRefreshing = true;
            refreshToken()
            // 这段代码一定要写，不然第一个请求的接口带过去的token还是原来的，要将第一个请求也缓存起来
            let retry = new Promise((resolve) => {
                cacheRequestArrHandle(async (token) => {
                    localStorage.setItem('token', JSON.stringify(token))
                    config.headers['token'] = 'Bearer' + token; // token为刷新完成后传入的token
                    // 将请求挂起
                    resolve(config)
                })
            })
            return retry;
        } else {
            let retry = new Promise((resolve) => {
                cacheRequestArrHandle((token) => {
                    localStorage.setItem('token', JSON.stringify(token))
                    config.headers['token'] = 'Bearer' + token; // token为刷新完成后传入的token
                    // 将请求挂起
                    resolve(config)
                })
            })
            return retry;
        }
    } else {
        return config
    }

}, (error) => {
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {
    window.isRefreshing = false;
    // 关闭loading
    tryHideFullScreenLoading();
    let { code, msg } = response.data;
    if (code == 200) {
        return response
    } else if (code == 271) {//token超过5天重新登陆
        let spType = localStorage.getItem('spType'),
            reflush = localStorage.getItem('reflush'),
            productType = localStorage.getItem('productType')
        localStorage.clear();
        localStorage.setItem('spType', spType);
        localStorage.setItem('reflush', reflush);
        localStorage.setItem('productType', productType);
        localStorage.setItem('isreload', true);
        router.push('/home');
        AllendLoading();
        return Promise.reject(new Error(msg))
    } else if (code == 272 || code == 275 || code == 282) {
        return response
    } else if (code == 273) {
        Message({
            message: msg,
            type: 'error',
            duration: 1000
        })
        localStorage.setItem('fetchDataPage', 1)
        localStorage.setItem('fetchDataSize', 20)
        setTimeout(() => {
            router.go(-1)
        }, 1200);
    } else {//报500
        Message.error(msg)// 提示错误消息
        return Promise.reject(new Error(msg))// 返回是一个只会失败的promise 到catch
    }

}, (error) => {
    // Message.error(error.message)// 提示错误消息
    Message.error('服务器异常，请稍后重试')// 提示错误消息
    return Promise.reject(error)
})

export function apiGet(url, params) {
    return new Promise((resolve, reject) => {
        service.get(url, params).then(res => { resolve(res) }).catch(err => { reject(err) })
    })
}

export function apiPost(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url, params).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
    })
}
export function RefreshToken() {
    refreshToken()
}

export default service

