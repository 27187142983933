<template>
    <el-input :placeholder="placeholderValue" auto-complete="new-password" clearable v-model="modelvalue"
        @input="e => (modelvalue = VerificationFormat(e, 1))" maxlength="6" @change="InputCode">
    </el-input>
</template>

<script>
import { VerificationFormatMethods } from '@/mixins/VerificationFormat'
export default {
    mixins: [VerificationFormatMethods],
    props: {
        placeholderValue: {
            type: String,
            default: '请输入验证码'
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        modelValue: {}
    },
    name: '',
    data() {
        return {
            modelvalue: this.modelValue,
            // auto-complete="new-password"
        }
    },
    watch: {
        modelValue() {
            this.modelvalue = this.modelValue
        }
    },
    methods: {
        // 获取输入的手机
        InputCode(value) {
            console.log(value)
            this.$emit('getCodeValue', value)
        }
    },
}

</script>

