import { apiPost } from "./service";

const http = {
  // 注册
  reg(params) {
    return apiPost('user/reg', params)
  },
  // 个人信息展示
  getUserInfo(params) {
    return apiPost('user/getUserInfo', params)
  },
  // 修改用户信息
  updateUserInfo(params) {
    return apiPost('user/updateUserInfo', params)
  },
  // 手机号修改
  updatePhone(params) {
    return apiPost('user/updatePhone', params)
  },
  // 切换用户类型接口
  changeUserType(params) {
    return apiPost('user/changeUserType', params)
  },
  // token刷新
  refreshToken(params) {
    return apiPost('user/refreshToken', params)
  },
  // 注销用户
  deleteUser(params) {
    return apiPost('user/deleteUser', params)
  },
  // 添加用户调查接口
  addUserSurveys(params) {
    return apiPost('user/addUserSurveys', params)
  },

  // 重置密码
  retrievePassword(params) {
    return apiPost('user/retrievePassword', params)
  },
  // 获取轮播信息
  frontPageCarouselList(params) {
    return apiPost('advertisement/frontPageCarouselList', params)
  },
  // 最新入驻企业列表接口
  findNewEnterpriseList(params) {
    return apiPost('enterprise/findNewEnterpriseList', params)
  },
  // 获取网页信息接口
  getWebInfo(params) {
    return apiPost('webinfo/getWebInfo', params)
  },
  // 获取首页产品列表
  popularProductsList(params) {
    return apiPost('product/popularProductsList', params)
  },
  // 退出登陆
  logout(params) {
    return apiPost('user/logout', params)
  },
  // 查找搜索的产品列表
  findSearchProductList(params) {
    return apiPost('product/findSearchProductList', params)
  },
  // 查找搜索的企业列表
  openEnterpriseSearchList(params) {
    return apiPost('enterprise/openEnterpriseSearchList', params)
  },
  // 5.10 findReviseEnterpriseAdministratorCode 用户获取修改企业管理员验证码接口
  findReviseEnterpriseAdministratorCode(params) {
    return apiPost('enterprise/findReviseEnterpriseAdministratorCode', params)
  },
  // 5.11 reviseEnterpriseAdministrator 用户修改企业管理员接口
  reviseEnterpriseAdministrator(params) {
    return apiPost('enterprise/reviseEnterpriseAdministrator', params)
  },
  // 5.12 getEnterpriseLevel 用户获取企业等级接口
  getEnterpriseLevel(params) {
    return apiPost('enterprise/getEnterpriseLevel', params)
  },
  // 查看产品类型列表
  findSearchProductTypeList(params) {
    return apiPost('product-type/findSearchProductTypeList', params)
  },
  // 阿里图像搜索接口
  aLiProductImageSearch(params) {
    return apiPost('product/aLiProductImageSearch', params)
  },
  // 阿里图像搜索列表接口
  aLiProductImageSearchList(params) {
    return apiPost('product/aLiProductImageSearchList', params)
  },

  // 首页图像搜索列表详情接口
  productImageSearchDetails(params) {
    return apiPost('product/productImageSearchDetails', params)
  },
  // 根据企业id获取企业列表详情接口
  findByIdEnterpriseInfo(params) {
    return apiPost('enterprise/findByIdEnterpriseInfo', params)
  },
  // 首页企业开放商品搜索列表接口
  openEnterpriseProductList(params) {
    return apiPost('product/openEnterpriseProductList', params)
  },
  // 用户搜索查看色库色彩列表接口
  findSearchColorDetailsList(params) {
    return apiPost('/color-details/findSearchColorDetailsList', params)
  },
  // 用户搜索查看色库列表接口
  findSearchColorLibraryList(params) {
    return apiPost('/color-library/findSearchColorLibraryList', params)
  },
  // 用户查看颜色搜索产品列表接口
  findColorSearchProductList(params) {
    return apiPost('/product/findColorSearchProductList', params)
  },
  // 用户查看企业颜色搜索产品列表接口
  findColorSearchEnterpriseProductList(params) {
    return apiPost('/product/findColorSearchEnterpriseProductList', params)
  },

  // 2--OssController
  // 2.5 uploadForumOssFile 帖子图片视频文件上传接口
  uploadForumOssFile(params) {
    return apiPost('forum/eduoss/fileoss/uploadForumOssFile', params)
  },
  // 2.7 uploadMediumAuditFile 媒体图片视频文件上传接口
  uploadMediumAuditFile(params) {
    return apiPost('forum/eduoss/fileoss/uploadMediumAuditFile', params)
  },
  // 主体识别时返回坐标
  aLiYunImageIdentification(params) {
    return apiPost('eduoss/fileoss/aLiYunImageIdentification', params)
  },
  // 头像修改
  uploadOssFile(params) {
    return apiPost('eduoss/fileoss/uploadOssFile', params)
  },
  // 产品图片视频上传接口
  uploadProductOssFile(params) {
    return apiPost('eduoss/fileoss/uploadProductOssFile', params)
  },
  // 订单模板公司logo上传接口
  uploadOssOrderEnterpriseLogoFile(params) {
    return apiPost('eduoss/fileoss/uploadOssOrderEnterpriseLogoFile', params)
  },

  // 4--企业审核模块---------------------------
  // 用户申请或者修改申请企业接口
  applicantEnterprise(params) {
    return apiPost('enterprise-audit/applicantEnterprise', params)
  },
  // 用户申请企业上传文件接口
  applicantEnterpriseUploadFile(params) {
    return apiPost('enterprise-audit/applicantEnterpriseUploadFile', params)
  },
  // 用户查询企业审核接口
  findEnterpriseAudit(params) {
    return apiPost('enterprise-audit/findEnterpriseAudit', params)
  },
  // 用户查询企业状态以及信息接口
  findEnterpriseAuditStateAndInfo(params) {
    return apiPost('enterprise-audit/findEnterpriseAuditStateAndInfo', params)
  },
  // 用户撤销企业审核接口
  revokeEnterpriseAudit(params) {
    return apiPost('enterprise-audit/revokeEnterpriseAudit', params)
  },

  // 5--企业模块---------------------------
  // 企业搜索列表接口
  enterpriseList(params) {
    return apiPost('enterprise/enterpriseList', params)
  },

  // 根据企业id获取企业信息接口
  findByIdEnterprise(params) {
    return apiPost('enterprise/findByIdEnterprise', params)
  },
  // 用户修改企业接口
  reviseEnterprise(params) {
    return apiPost('enterprise/reviseEnterprise', params)
  },
  // 企业解散接口
  disbandEnterprise(params) {
    return apiPost('enterprise/disbandEnterprise', params)
  },


  // 6--用户申请加入企业模块---------------------------
  // 用户申请加入企业接口
  userApplyJoinEnterprise(params) {
    return apiPost('into-eapply/userApplyJoinEnterprise', params)
  },
  // 用户申请退出企业接口
  userApplyQuitEnterprise(params) {
    return apiPost('into-eapply/userApplyQuitEnterprise', params)
  },
  // 企业入驻时清空用户消息
  applyEnterpriseEmptyUserInfo(params) {
    return apiPost('into-eapply/applyEnterpriseEmptyUserInfo', params)
  },
  // 企业审批人员列表接口
  enterpriseApproval(params) {
    return apiPost('into-eapply/enterpriseApproval', params)
  },
  // 企业审批人员列表详情接口
  enterpriseApprovalDetails(params) {
    return apiPost('into-eapply/enterpriseApprovalDetails', params)
  },
  // 企业审批人员通过接口
  enterpriseApprovalPass(params) {
    return apiPost('into-eapply/enterpriseApprovalPass', params)
  },
  // 用户拒绝加入企业接口
  enterpriseApprovalDisagree(params) {
    return apiPost('into-eapply/enterpriseApprovalDisagree', params)
  },
  // 企业根据手机号查找用户接口
  findByPhoneUser(params) {
    return apiPost('into-eapply/findByPhoneUser', params)
  },
  // 企业邀请用户接口
  enterpriseInviteUser(params) {
    return apiPost('into-eapply/enterpriseInviteUser', params)
  },
  // 企业撤销邀请用户接口
  enterpriseRevokeJoinUser(params) {
    return apiPost('into-eapply/enterpriseRevokeJoinUser', params)
  },
  // 企业审批人员清空指定消息
  enterpriseApprovalEmptyOne(params) {
    return apiPost('into-eapply/enterpriseApprovalEmptyOne', params)
  },




  // 7--企业用户模块---------------------------
  // 用户查看工作牌接口
  userFindNameCard(params) {
    return apiPost('enterprise-user/userFindNameCard', params)
  },
  // 用户修改工作牌接口
  userReviseNameCard(params) {
    return apiPost('enterprise-user/userReviseNameCard', params)
  },
  // 用户查看申请或邀请加入企业列表接口
  userFindJoinEnterprise(params) {
    return apiPost('into-eapply/userFindJoinEnterprise', params)
  },
  // 用户查看申请或邀请加入企业列表详情接口
  userFindJoinEnterpriseDetails(params) {
    return apiPost('into-eapply/userFindJoinEnterpriseDetails', params)
  },
  // 用户撤销加入企业或用户撤销退出企业接口
  userRevokeJoinEnterprise(params) {
    return apiPost('into-eapply/userRevokeJoinEnterprise', params)
  },
  // 用户清空指定消息
  userApprovalEmptyOne(params) {
    return apiPost('into-eapply/userApprovalEmptyOne', params)
  },
  // 用户同意加入企业接口
  userAgreeJoinEnterprise(params) {
    return apiPost('into-eapply/userAgreeJoinEnterprise', params)
  },
  // 用户拒绝加入企业接口
  userRefuseJoinEnterprise(params) {
    return apiPost('into-eapply/userRefuseJoinEnterprise', params)
  },
  // 企业用户列表接口
  enterpriseUserList(params) {
    return apiPost('enterprise-user/enterpriseUserList', params)
  },
  // 企业用户列表详情接口
  enterpriseUserListDetails(params) {
    return apiPost('enterprise-user/enterpriseUserListDetails', params)
  },
  // 企业用户设置权限接口
  enterpriseUserRevisePermission(params) {
    return apiPost('enterprise-user/enterpriseUserRevisePermission', params)
  },
  // 移除企业用户接口
  removeEnterpriseUser(params) {
    return apiPost('enterprise-user/removeEnterpriseUser', params)
  },
  // 企业用户批量设置权限接口
  enterpriseUserRevisePermissionList(params) {
    return apiPost('enterprise-user/enterpriseUserRevisePermissionList', params)
  },

  // 8--企业产品模块---------------------------
  // 用户上传产品接口
  addProduct(params) {
    return apiPost('product/addProduct', params)
  },
  // 用户查看企业产品列表详情接口
  findEnterpriseProductDetails(params) {
    return apiPost('product/findEnterpriseProductDetails', params)
  },
  //  用户修改企业产品接口
  reviseEnterpriseProduct(params) {
    return apiPost('product/reviseEnterpriseProduct', params)
  },
  //  用户移除企业产品接口
  removeEnterpriseProduct(params) {
    return apiPost('product/removeEnterpriseProduct', params)
  },
  // 用户搜索查看企业产品列表接口
  findSearchEnterpriseProductList(params) {
    return apiPost('product/findSearchEnterpriseProductList', params)
  },
  // 阿里企业图像搜索接口
  aLiEnterpriseProductImageSearch(params) {
    return apiPost('product/aLiEnterpriseProductImageSearch', params)
  },
  // 阿里企业图像搜索列表接口
  aLiEnterpriseProductImageSearchList(params) {
    return apiPost('product/aLiEnterpriseProductImageSearchList', params)
  },

  // 产品浏览足迹列表接口
  productFootmarksList(params) {
    return apiPost('product/productFootmarksList', params)
  },
  // 移除全部浏览足迹接口
  removeAllFootmarks(params) {
    return apiPost('product/removeAllFootmarks', params)
  },
  // 用户查看产品库存接口
  findProductInventory(params) {
    return apiPost('product/findProductInventory', params)
  },
   // 8.21 popularProductsTypeList 热门商品分类列表接口
   popularProductsTypeList(params) {
    return apiPost('product/popularProductsTypeList', params)
  },





  // 9--库存记录模块---------------------------
  // 用户修改企业产品库存接口
  updateProductStock(params) {
    return apiPost('stock-record/updateProductStock', params)
  },
  // 用户查看产品库存单据列表接口
  productStockList(params) {
    return apiPost('stock-record/productStockList ', params)
  },
  // 用户查看产品库存单据详情接口
  productStockListDetails(params) {
    return apiPost('stock-record/productStockListDetails', params)
  },
  // 用户搜索产品库存单据接口
  productStockSearch(params) {
    return apiPost('stock-record/productStockSearch', params)
  },
  // 产品库存单据操作人搜索查询接口
  productStockSearchOperator(params) {
    return apiPost('stock-record/productStockSearchOperator', params)
  },
  // 用户修改企业产品库存单据作废接口
  updateProductStockBecomeInvalid(params) {
    return apiPost('stock-record/updateProductStockBecomeInvalid', params)
  },



  // 10--订单模板模块---------------------------
  // 用户修改企业订单模板接口
  updateEnterpriseOrderTemplate(params) {
    return apiPost('order-template/updateEnterpriseOrderTemplate', params)
  },
  // 用户获取企业订单模板接口
  getEnterpriseOrderTemplate(params) {
    return apiPost('order-template/getEnterpriseOrderTemplate', params)
  },



  // 11--企业客户模块---------------------------
  // 新增企业客户接口
  addEnterpriseCustomer(params) {
    return apiPost('enterprise-customer/addEnterpriseCustomer', params)
  },
  // 企业客户搜索列表接口
  searchListEnterpriseCustomer(params) {
    return apiPost('enterprise-customer/searchListEnterpriseCustomer', params)
  },
  // 企业客户列表详情接口
  enterpriseCustomerDetails(params) {
    return apiPost('enterprise-customer/enterpriseCustomerDetails', params)
  },
  // 修改企业客户接口
  reviseEnterpriseCustomer(params) {
    return apiPost('enterprise-customer/reviseEnterpriseCustomer', params)
  },
  // 删除业客户接口
  removeEnterpriseCustomer(params) {
    return apiPost('enterprise-customer/removeEnterpriseCustomer', params)
  },

  // 12--进行中订单模块---------------------------
  // 用户添加订单接口
  addOrderActive(params) {
    return apiPost('order-active/addOrderActive', params)
  },
  // 用户查看自己的订单草稿接口
  findOrderActiveNotes(params) {
    return apiPost('order-active/findOrderActiveNotes', params)
  },
  // 用户新增或修改进行中的订单草稿接口
  addReviseOrderActive(params) {
    return apiPost('order-active/addReviseOrderActive', params)
  },
  // 删除编辑中订单草稿接口
  removeOrderActive(params) {
    return apiPost('order-active/removeOrderActive', params)
  },
  // 取消订单接口
  scrappedOrderActive(params) {
    return apiPost('order-active/scrappedOrderActive', params)
  },
  // 订单状态发货收货修改接口
  reviseOrderActiveState(params) {
    return apiPost('order-active/reviseOrderActiveState', params)
  },
  // 订单状态修改收款接口
  reviseOrderActiveStateCollection(params) {
    return apiPost('order-active/reviseOrderActiveStateCollection', params)
  },
  // 修改未发货订单接口  
  reviseOrderActive(params) {
    return apiPost('order-active/reviseOrderActive', params)
  },





  // 13--退货订单模块---------------------------
  // 用户搜索查看退货列表接口
  findSearchOrderReturnList(params) {
    return apiPost('order-return/findSearchOrderReturnList', params)
  },
  // 未完成订单退货接口
  undoneOrderReturn(params) {
    return apiPost('order-return/undoneOrderReturn', params)
  },
  // 已完成订单退货接口
  completedOrderReturn(params) {
    return apiPost('order-return/completedOrderReturn', params)
  },
  // 用户查看关联单的全部退货列表接口
  findAllOrderReturnList(params) {
    return apiPost('order-return/findAllOrderReturnList', params)
  },
  // 用户搜索查看退货列表详细信息接口
  findOrderReturnDetails(params) {
    return apiPost('order-return/findOrderReturnDetails', params)
  },
  // 取消订单退货接口
  cancelOrderReturn(params) {
    return apiPost('order-return/cancelOrderReturn', params)
  },
  // 退货订单状态修改退货完成接口
  updateOrderReturnState(params) {
    return apiPost('order-return/updateOrderReturnState', params)
  },



  // 14--完成订单模块---------------------------
  // 用户搜索查看全部订单列表接口
  findSearchAllOrderList(params) {
    return apiPost('order-completed/findSearchAllOrderList', params)
  },
  // 用户搜索查看全部订单列表详细信息接口
  findAllOrderDetails(params) {
    return apiPost('order-completed/findAllOrderDetails', params)
  },
  // 用户查看完成订单产品单价信息接口
  findOrderCompletedUnitPriceInfo(params) {
    return apiPost('order-completed/findOrderCompletedUnitPriceInfo', params)
  },
  // 用户查看客户订单统计接口
  findCustomerOrderStats(params) {
    return apiPost('order-completed/findCustomerOrderStats', params)
  },




  // 15--财务模块---------------------------
  // 财务统计接口
  amountTotal(params) {
    return apiPost('e-amount/amountTotal', params)
  },
  // 产品库存单据财务数据列表接口
  productStockAmountList(params) {
    return apiPost('stock-record/productStockAmountList', params)
  },
  // 产品库存单据财务数据列表详情接口
  productStockAmountListDetails(params) {
    return apiPost('stock-record/productStockAmountListDetails', params)
  },




  // 18--产品交流帖子模块---------------------------
  // 用户上传帖子接口
  addForumArticle(params) {
    return apiPost('forum/forum-products/addForumArticle', params)
  },
  // 帖子搜索列表接口
  forumArticleList(params) {
    return apiPost('forum/forum-products/forumArticleList', params)
  },
  // 帖子列表详情接口
  forumArticleDetails(params) {
    return apiPost('forum/forum-products/forumArticleDetails', params)
  },
  // 用户移除帖子接口
  removeForumArticle(params) {
    return apiPost('forum/forum-products/removeForumArticle', params)
  },


  // 19--产品交流帖子模块---一级评论模块---------------------------
  // 用户发表评论接口
  addForumPostComment(params) {
    return apiPost('forum/forum-products-fcomment/addForumPostComment', params)
  },
  // 帖子评论列表接口
  forumPostCommentList(params) {
    return apiPost('forum/forum-products-fcomment/forumPostCommentList', params)
  },
  // 用户移除评论接口
  removeForumPostComment(params) {
    return apiPost('forum/forum-products-fcomment/removeForumPostComment', params)
  },


  // 20--产品交流帖子模块---二级评论模块---------------------------
  // 用户发表评论接口
  addForumPostSecondaryComment(params) {
    return apiPost('forum/forum-products-mcomment/addForumPostSecondaryComment', params)
  },
  // 帖子二级评论列表接口
  forumPostSecondaryCommentList(params) {
    return apiPost('forum/forum-products-mcomment/forumPostSecondaryCommentList', params)
  },
  // 用户移除二级评论
  removeForumPostSecondaryComment(params) {
    return apiPost('forum/forum-products-mcomment/removeForumPostSecondaryComment', params)
  },


  // 21--识料杂谈帖子模块---------------------------
  // 用户上传帖子接口
  addForumChatArticle(params) {
    return apiPost('forum/forum-chat/addForumChatArticle', params)
  },
  // 帖子搜索列表接口
  forumChatArticleList(params) {
    return apiPost('forum/forum-chat/forumChatArticleList', params)
  },
  // 帖子列表详情接口
  forumChatArticleDetails(params) {
    return apiPost('forum/forum-chat/forumChatArticleDetails', params)
  },
  // 用户移除帖子接口
  removeForumChatArticle(params) {
    return apiPost('forum/forum-chat/removeForumChatArticle', params)
  },



  // 22--识料杂谈帖子模块---一级评论模块---------------------------
  // 用户发表评论接口
  addForumChatPostComment(params) {
    return apiPost('forum/forum-chat-fcomment/addForumChatPostComment', params)
  },
  // 帖子评论列表接口
  forumChatPostCommentList(params) {
    return apiPost('forum/forum-chat-fcomment/forumChatPostCommentList', params)
  },
  // 用户移除评论接口
  removeForumChatPostComment(params) {
    return apiPost('forum/forum-chat-fcomment/removeForumChatPostComment', params)
  },

  // 23--识料杂谈帖子模块---二级评论模块---------------------------
  // 用户发表二级评论接口
  addForumChatPostSecondaryComment(params) {
    return apiPost('forum/forum-chat-mcomment/addForumChatPostSecondaryComment', params)
  },
  //  帖子二级评论列表接口
  forumChatPostSecondaryCommentList(params) {
    return apiPost('forum/forum-chat-mcomment/forumChatPostSecondaryCommentList', params)
  },
  //  用户移除二级评论接口
  removeForumChatPostSecondaryComment(params) {
    return apiPost('forum/forum-chat-mcomment/removeForumChatPostSecondaryComment', params)
  },

  // 24 ForumNewsController -- 行业动态帖子模块
  // 帖子搜索列表接口
  forumNewsArticleList(params) {
    return apiPost('forum/forum-news/forumNewsArticleList', params)
  },
  // 帖子列表详情接口
  forumNewsArticleDetails(params) {
    return apiPost('forum/forum-news/forumNewsArticleDetails', params)
  },
  // 24.3 addForumNews 添加行业动态帖子接口
  addForumNews(params) {
    return apiPost('forum/forum-news/addForumNews', params)
  },


  // 25 ForumNewsFcommentController -- 一级评论模块
  addForumNewsPostComment(params) {  // 用户发表评论接口
    return apiPost('forum/forum-news-fcomment/addForumNewsPostComment', params)
  },
  forumNewsPostCommentList(params) {  // 帖子评论列表接口
    return apiPost('forum/forum-news-fcomment/forumNewsPostCommentList', params)
  },

  // 26--二级评论模块
  // 26.1 addForumNewsPostSecondaryComment  用户发表二级评论
  addForumNewsPostSecondaryComment(params) {
    return apiPost('forum/forum-news-mcomment/addForumNewsPostSecondaryComment', params)
  },
  // 26.2 forumNewsPostSecondaryCommentList  帖子二级评论列表
  forumNewsPostSecondaryCommentList(params) {
    return apiPost('forum/forum-news-mcomment/forumNewsPostSecondaryCommentList', params)
  },

  // 27--职位招聘模块
  //  企业用户添加职位招聘接口
  addForumRecruit(params) {
    return apiPost('forum/forum-recruit/addForumRecruit', params)
  },
  //  企业内部职位招聘帖子列表接口
  forumRecruitEnterpriseArticleList(params) {
    return apiPost('forum/forum-recruit/forumRecruitEnterpriseArticleList', params)
  },
  //  企业内部职位招聘帖子列表详情接口
  forumRecruitEnterpriseArticleDetails(params) {
    return apiPost('forum/forum-recruit/forumRecruitEnterpriseArticleDetails', params)
  },
  //  职位招聘帖子列表接口
  forumRecruitArticleList(params) {
    return apiPost('forum/forum-recruit/forumRecruitArticleList', params)
  },
  //  职位招聘帖子列表详情接口
  forumRecruitArticleDetails(params) {
    return apiPost('forum/forum-recruit/forumRecruitArticleDetails', params)
  },
  //  职位招聘帖子修改接口
  reviseForumRecruit(params) {
    return apiPost('forum/forum-recruit/reviseForumRecruit', params)
  },
  //  职位招聘帖子移除接口
  removeForumRecruit(params) {
    return apiPost('forum/forum-recruit/removeForumRecruit', params)
  },
  // //  用户查看自己简历列表
  // forumResumeMyList(params) {
  //   return apiPost('forum/forum-resume/forumResumeMyList', params)
  // },
  // 用户查看自己简历列表详情
  forumResumeMyListDetails(params) {
    return apiPost('forum/forum-resume/forumResumeMyListDetails', params)
  },
  // 切换简历状态
  handOffForumResumeState(params) {
    return apiPost('forum/forum-resume/handOffForumResumeState', params)
  },
  // 用户删除简历接口
  removeForumResume(params) {
    return apiPost('forum/forum-resume/removeForumResume?', params)
  },
  // // 27.2 forumRecruitEnterpriseArticleList 企业内部职位招聘帖子列表接口
  // forumRecruitEnterpriseArticleList(params) {
  //   return apiPost('forum/forum-resume/forumRecruitEnterpriseArticleList?', params)
  // },




  // 28--供应求购模块
  //  用户发布保存供应求购帖子接口
  addForumBuy(params) {
    return apiPost('forum/forum-buy/addForumBuy', params)
  },
  //  供应求购帖子搜索列表接口
  forumBuySearchList(params) {
    return apiPost('forum/forum-buy/forumBuySearchList', params)
  },
  //  供应求购帖子搜索列表详情接口
  forumBuySearchListDetails(params) {
    return apiPost('forum/forum-buy/forumBuySearchListDetails', params)
  },
  //  用户查看自己的供应求购帖子列表接口
  interiorForumBuySearchList(params) {
    return apiPost('forum/forum-buy/interiorForumBuySearchList', params)
  },
  //  用户查看自己的供应求购帖子列表详情接口
  interiorForumBuySearchListDetails(params) {
    return apiPost('forum/forum-buy/interiorForumBuySearchListDetails', params)
  },
  //  用户移除供应求购帖子接口
  removeForumBuy(params) {
    return apiPost('forum/forum-buy/removeForumBuy', params)
  },
  //  用户修改供应求购帖子接口
  reviseForumBuy(params) {
    return apiPost('forum/forum-buy/reviseForumBuy', params)
  },




  // 29--IP地址模块
  //  获取请求ip地址接口
  getIpAddress(params) {
    return apiPost('ip/ip-address/getIpAddress', params)
  },


  //30-- 产品收藏模块
  //  用户收藏取消收藏产品接口
  addCancelProductFavorites(params) {
    return apiPost('product-favorites/addCancelProductFavorites', params)
  },
  //  用户查看收藏产品列表接口
  findProductFavoritesList(params) {
    return apiPost('product-favorites/findProductFavoritesList', params)
  },
  // 34 MediumAuditController 媒体审核模块
  // 用户申请媒体审核接口
  applicantMediumAudit(params) {
    return apiPost('forum/medium-audit/applicantMediumAudit', params)
  },
  // 34.1 findMediumAudit   用户查询媒体审核接口
  findMediumAudit(params) {
    return apiPost('forum/medium-audit/findMediumAudit ', params)
  },
  // 34.3 reviseMediumAudit 用户修改媒体审核接口
  reviseMediumAudit(params) {
    return apiPost('forum/medium-audit/reviseMediumAudit ', params)
  },
  // 34.4 revokeMediumAudit 用户撤销媒体审核接口
  revokeMediumAudit(params) {
    return apiPost('forum/medium-audit/revokeMediumAudit ', params)
  },

  // 35 ForumMediumController 媒体模块
  // 35.1 findForumMedium 用户查询媒体接口
  findForumMedium(params) {
    return apiPost('forum/forum-medium/findForumMedium', params)
  },
  // 35.4 reviseForumMedium 用户修改媒体接口
  reviseForumMedium(params) {
    return apiPost('forum/forum-medium/reviseForumMedium', params)
  },
  // 35.6 findForumMediumState 用户查看媒体状态接口
  findForumMediumState(params) {
    return apiPost('forum/forum-medium/findForumMediumState', params)
  },

  // 40 ForumResumeController-- 用户简历模块

  // 用户添加简历接口
  addForumResume(params) {
    return apiPost('forum/forum-resume/addForumResume', params)
  },
  // 40.2 forumResumeMyList: 用户查看自己简历列表接口
  forumResumeMyList(params) {
    return apiPost('forum/forum-resume/forumResumeMyList', params)
  },

  // 40.4 reviseMyForumResume 用户修改简历接口
  reviseMyForumResume(params) {
    return apiPost('forum/forum-resume/reviseMyForumResume', params)
  },
  // 40.7 forumResumeList 查看公开简历列表接口
  forumResumeList(params) {
    return apiPost('forum/forum-resume/forumResumeList', params)
  },

  // 41.2 forumResumeIntoMyList: 用户简历投递取消投递接口
  addCancelForumResumeInto(params) {
    return apiPost('forum/forum-resume-into/addCancelForumResumeInto', params)
  },

  // 41.2 forumResumeIntoMyList: 用户投递职位列表接口
  forumResumeIntoMyList(params) {
    return apiPost('forum/forum-resume-into/forumResumeIntoMyList', params)
  },
  // 41.4 forumResumeIntoEnterprisePostList 企业接收简历职位列表接口
  forumResumeIntoEnterprisePostList(params) {
    return apiPost('forum/forum-resume-into/forumResumeIntoEnterprisePostList', params)
  },
  // 51.1 getEnterpriseQcCodeInfo?: 用户获取企业二维码路径接口
  getEnterpriseQcCodeInfo(params) {
    return apiPost('/qc-code-info/getEnterpriseQcCodeInfo', params)
  },


}

export { http }




