<template>
    <div>
        <el-form ref="registerForm" :model="registerForm" label-width="70px" :rules="shortMsgRules" label-position="left">
            <el-form-item prop="phone" label="手机号">
                <PhoneInput :modelValue="registerForm.phone" @getPhoneNumber="getPhoneNumber"></PhoneInput>
            </el-form-item>
            <el-form-item prop="code" label="验证码">
                <GetCode :phone="registerForm.phone" :type="'registerForm'"></GetCode>
            </el-form-item>
            <!-- <el-form-item> -->
            <div class="f-c">
                <el-button class="login-submit" @click.prevent="register('registerForm')" v-if="type == 'login'">注册
                </el-button>
                <el-button class="login-submit" @click.prevent="changePhone('registerForm')" v-else>确定</el-button>
            </div>

            <!-- </el-form-item> -->
        </el-form>
        <slot name="other"></slot>
    </div>
</template>
<script>
import { shortMsgRules } from "@/utils/FormRules"

export default {
    props: {
        type: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            // 注册
            registerForm: {
                phone: '',
                code: ''
            },
            // 短信规则
            shortMsgRules,
        }
    },
    methods: {
        getPhoneNumber(data) {
            this.registerForm.phone = data.value
        },
        // 注册-注册完是直接登陆
        register(form) {
            let that = this,
                code = that.$getStorage('codeValue') ? that.$getStorage('codeValue').code : '',
                data = {
                    phone: that.registerForm.phone,
                    code,
                    source: 'pc'
                }
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$http.reg(data).then(res => {
                        if (res.code == 200) {
                            let { token, userInfo } = res.data;
                            that.$setStorage('token', token);
                            that.$store.commit('saveUserName', userInfo.userName)
                            that.$store.commit('saveUserId', userInfo.userId)
                            that.$store.commit('saveUserAvatarUrl', userInfo.userAvatarUrl)
                            that.$store.commit('saveUserPhone', userInfo.phone)
                            // that.$setStorage('tokenDate', that.$common.timeToYMD());
                            let timeUpdate = new Date().getTime();// 获取当前的时间戳
                            that.$setStorage('timeUpdate',timeUpdate);
                            that.$removeStorage('codeValue');
                            that.$parent.handleClose()
                            that.$common.message(res.msg, 'success')
                        } else {
                            that.$common.message(res.msg, 'error')
                        }
                    })
                }
            })
        },
        // 更改手机号
        changePhone(form) {
            let that = this,
                code = that.$getStorage('codeValue') ? that.$getStorage('codeValue').code : '',
                data = {
                    userId: parseInt(that.$store.state.userId),
                    nextPhone: that.registerForm.phone,
                    code,
                    token: that.$getStorage('token')
                }
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$http.updatePhone(data).then(res => {
                        if (res.code == 200) {
                            that.$removeStorage('codeValue');
                            that.$message({ message: res.msg, type: 'success' });
                            that.$store.commit('saveUserPhone', data.nextPhone)
                            that.$common.RefreshCurrent();
                        } else {
                            that.$common.message(res.msg, 'error')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.login-submit {
    margin: auto;
    width: 160px !important;
    height: 60px;
    // line-height: 60px;
    text-align: center;
    border-radius: 10px;
    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
    color: #fff;
    font-size: 18px;
    font-family: 'Microsoft YaHei';
    border: none;
    margin-top: 20px;

}
</style>