<template>
    <div>
        <el-dialog :title="title" :width="width" :visible="visible"
            :close-on-click-modal="closeOnClickModal" :lock-scroll="lockScroll"
            :modal-append-to-body="modalAppendToBody" @close="clickClose">
            <slot />
        </el-dialog>
    </div>
</template>
   
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        width:{
            type: String,
            default: "50%"
        },
        closeOnClickModal: {//是否可以通过点击 modal 关闭 Dialog
            type: Boolean,
            default: false
        },
        lockScroll: {//是否在 Dialog 出现时将 body 滚动锁定
            type: Boolean,
            default: true
        },
        modalAppendToBody: {//遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            topTitle : this.title
        }
    },
    methods:{
        clickClose(){
            this.$emit('closeDialog',false)
        }
    }
};
</script>