<template>
    <el-input :placeholder="placeholderValue" clearable v-model="modelvalue" :prefix-icon="prefixIcon" @clear="clearPhone"
        @change="changePhone" @keyup.enter.native="enterSearch" :disabled="disableValue" @input="handleInput"
        :maxlength="maxlength">
    </el-input>
</template>

<script>
import { VerificationFormatMethods } from '@/mixins/VerificationFormat'
export default {
    mixins: [VerificationFormatMethods],
    props: {
        placeholderValue: {
            type: String,
            default: '请输入手机号'
        },
        modelValue: {},
        prefixIcon: {
            type: String,
            default: ''
        },
        // 最大的长度
        maxlength: {
            type: Number,
            default: 11
        },
        // 长度限制
        LengthLimit: {
            type: String,
            default: '11'
        },
        differentType: {
            type: String,
            default: ''
        },
        disableValue: {
            type: Boolean,
            default: false
        }
    },
    name: '',
    data() {
        return {
            modelvalue: this.modelValue
        }
    },
    watch: {
        modelValue() {
            this.modelvalue = this.modelValue
        }
    },
    methods: {
        handleInput(e) {
            switch (this.LengthLimit) {
                case '11':
                    this.modelvalue = this.VerificationFormat(e, 1)
                    break;
                case '16':
                    this.modelvalue = this.VerificationFormat(e, 4)
                    break;
            }
        },
        // 获取输入的手机
        changePhone(value) {
            this.$emit('getPhoneNumber', { value, type: this.differentType })
        },
        // 监听清空键
        clearPhone() {
            this.$emit('clearPhone')
        },
        // 人员搜索框--回车键搜索
        enterSearch() {
            this.$emit('searchEmployee')
        }
    },
}
</script>
