// 供应链模块
export default [{
    path: '/SupplyIndex',//供应首页
    name: 'SupplyIndex',
    component: () => import('@/pages/SupplyChainManagement/SupplyIndex'),
    meta: { showFooter: true }
},

// 客户管理------------------------------------------------------
{
    path: '/CustomerList',//客户列表
    name: 'CustomerList',
    component: () => import('@/pages/SupplyChainManagement/CustomerManagement/CustomerList'),
    meta: { showFooter: true }
},
// 产品库----------------------------------------------------------
{
    path: '/ProductLibrary',//产品库
    name: 'ProductLibrary',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ProductLibrary'),
    meta: { showFooter: true }
}, {
    path: '/ProductLibraryAdd',//产品库新增产品
    name: 'ProductLibraryAdd',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ProductLibraryAdd'),
    meta: { showFooter: true }
},{
    path: '/ProductModify',//产品库新修改产品
    name: 'ProductModify',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ProductModify'),
    meta: { showFooter: true }
}, {
    path: '/ProductLibraryDetail',//产品库产品详情
    name: 'ProductLibraryDetail',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ProductLibraryDetail'),
    meta: { showFooter: true }
}, {
    path: '/productLibrarySearchResult',//产品库搜索结果页
    name: 'productLibrarySearchResult',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/productLibrarySearchResult'),
    meta: { showFooter: true }
}, {
    path: '/ModifyInventory',//修改库存
    name: 'ModifyInventory',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ModifyInventory'),
    meta: { showFooter: true }
}, {
    path: '/ProductLibraryColoursearch',//修改库存
    name: 'ProductLibraryColoursearch',
    component: () => import('@/pages/SupplyChainManagement/ProductLibraryManagement/ProductLibrary_Coloursearch'),
    meta: { showFooter: true }
},

// 企业信息----------------------------------------------------------
// Transfer
{
    path: '/SupplierInfo',//企业详情
    name: 'SupplierInfo',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/SupplierInfo'),
    meta: { showFooter: true }
}, {
    path: '/EnterpriseStateInfo',//企业状态列表
    name: 'EnterpriseStateInfo',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/EnterpriseStateInfo'),
    meta: { showFooter: true }
}, {
    path: '/EditEnterpriseInfo',//编辑企业信息
    name: 'EditEnterpriseInfo',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/EditEnterpriseInfo'),
    meta: { showFooter: true }
}, {
    path: '/JoinEnterpriseList',//加入企业列表
    name: 'JoinEnterpriseList',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/JoinEnterpriseList'),
    meta: { showFooter: true,keepAlive: true }
}, {
    path: '/Transferadmin',//加入企业列表
    name: 'Transferadmin',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/Transferadmin'),
    meta: { showFooter: true }
},
// 企业消息------------------------------------------------------
{
    path: '/EnterpriseNews',//企业消息
    name: 'EnterpriseNews',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/EnterpriseNews'),
    meta: { showFooter: true }
},
// 工作牌---------------------------------------------------------
{
    path: '/workCard',
    name: 'WorkCard',
    component: () => import('@/pages/SupplyChainManagement/Enterprise/WorkCard'),
    meta: { showFooter: true }
},
//订单管理------------------------------------------------------------------
{
    path: '/OrderList',//订单列表
    name: 'OrderList',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderList'),
    meta: { showFooter: true }
}, 
{
    path: '/OrderListInProgress',//订单列表_InProgress
    name: 'OrderListInProgress',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderListInProgress'),
    meta: { showFooter: true }
}, {
    path: '/OrderListCompleted',//订单列表_Completed
    name: 'OrderListCompleted',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderListCompleted'),
    meta: { showFooter: true }
}, {
    path: '/OrderListReturnOrder',//订单列表_Completed
    name: 'OrderListReturnOrder',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderListReturnOrder'),
    meta: { showFooter: true }
}, {
    path: '/AddOrder',//新增订单
    name: 'AddOrder',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/AddOrder'),
    meta: { showFooter: true }
}, {
    path: '/UnshippedOrderModification',//未发货时修改订单
    name: 'UnshippedOrderModification',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/UnshippedOrderModification'),
    meta: { showFooter: true }
}, {
    path: '/OrderDetails',//订单详情
    name: 'OrderDetails',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderDetails'),
    meta: { showFooter: true }
}, {
    path: '/AssociatedOrder',//关联订单页
    name: 'AssociatedOrder',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/AssociatedOrder'),
    meta: { showFooter: true }
}, {
    path: '/AddReturnDoc',//新增退货单
    name: 'AddReturnDoc',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/AddReturnDoc'),
    meta: { showFooter: true }
}, {
    path: '/ReturnOrderDetails',//退货单详情
    name: 'ReturnOrderDetails',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/ReturnOrderDetails'),
    meta: { showFooter: true }
}, {
    path: '/PrintPage',//打印页
    name: 'PrintPage',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/PrintPage'),
    meta: { showFooter: true }
}, {
    path: '/OrderStatistics',//订单统计页
    name: 'OrderStatistics',
    component: () => import('@/pages/SupplyChainManagement/OrderManagement/OrderStatistics'),
    meta: { showFooter: true }
},
// 人员管理------------------------------------------------------
{
    path: '/ApprovalInfo',//人员管理审批信息
    name: 'ApprovalInfo',
    component: () => import('@/pages/SupplyChainManagement/personnelManagement/ApprovalInfo'),
    meta: { showFooter: true }
}, {
    path: '/EmployeeList',//员工列表
    name: 'EmployeeList',
    component: () => import('@/pages/SupplyChainManagement/personnelManagement/EmployeeList'),
    meta: { showFooter: true }
}, {
    path: '/PermissionBatchManagement',//员工列表
    name: 'PermissionBatchManagement',
    component: () => import('@/pages/SupplyChainManagement/personnelManagement/PermissionBatchManagement'),
    meta: { showFooter: true }
},{
    path: '/EmployeeInfo',
    name: 'EmployeeInfo',
    component: () => import('@/pages/SupplyChainManagement/personnelManagement/EmployeeInfo'),
    meta: { showFooter: true }
},
//财务管理--------------------------------------------------------------
{
    path: '/DocTemplate',//单据模板页
    name: 'DocTemplate',
    component: () => import('@/pages/SupplyChainManagement/FinancialManagement/DocTemplate'),
    meta: { showFooter: true }
}, {
    path: '/FinancialStatistics',//财务统计页
    name: 'FinancialStatistics',
    component: () => import('@/pages/SupplyChainManagement/FinancialManagement/FinancialStatistics'),
    meta: { showFooter: true }
}, {
    path: '/FinancialRecordsDetails',//财务记录明细
    name: 'FinancialRecordsDetails',
    component: () => import('@/pages/SupplyChainManagement/FinancialManagement/FinancialRecordsDetails'),
    meta: { showFooter: true }
},
//库存管理-------------------------------------------------------------------
// {
//     path: '/ModifyInventory',//修改库存
//     name: 'ModifyInventory',
//     component: () => import('@/pages/SupplyChainManagement/InventoryManagement/ModifyInventory'),
//     meta: { showFooter: true }
// }, 
{
    path: '/InventoryRecords',//库存记录
    name: 'InventoryRecords',
    component: () => import('@/pages/SupplyChainManagement/InventoryManagement/InventoryRecords'),
    meta: { showFooter: true }
}, {
    path: '/InventoryRecordsDetails',//库存记录明细
    name: 'InventoryRecordsDetails',
    component: () => import('@/pages/SupplyChainManagement/InventoryManagement/InventoryRecordsDetails'),
    meta: { showFooter: true }
}]