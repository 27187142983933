/**
 * 作用：阻止鼠标滚轮改变type=number时的数值
 * 使用：<el-input type="number" v-stopNumberMousewhell></el-input>
 */

const stopNumberMousewhell = {
    inserted: function (el) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
        ele.addEventListener("mousewheel",()=>{
            ele.blur();
        })
      }
 }

 export default stopNumberMousewhell