<template>
    <div>
        <div class="container f-c">
            <!-- 轮播图 -->
            <div class="show">
                <el-carousel indicator-position="outside" :autoplay="false">
                    <el-carousel-item v-if="videoUrl">
                        <video controls :src="videoUrl" type="video/mp4" width="300px" height="350px" muted>
                            <source :src="videoUrl" type="video/mp4">
                            您的浏览器不支持 video 标签。
                        </video>
                    </el-carousel-item>
                    <el-carousel-item v-for="(item, index) of proUrlList" :key="index">
                        <el-image :src="item" fit="contain" :preview-src-list="proUrlList"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="info f-cl f1">
                <div class="product-info">
                    <div class="top f-c-b">
                        <span>{{title}}</span>
                        <div class="product-collect f-cl-c" @click="handleCollect" v-if="$route.path == '/productDetail'">
                            <i class="el-icon-star-on" style="color: #ff9800; font-size: 24px;"
                                v-show="isCollect == 'Y'"></i>
                            <i class="el-icon-star-off" style="font-size: 24px; color: #ff9800;"
                                v-show="isCollect == 'N'"></i>
                            <span style="font-size: 14px;">收藏</span>
                        </div>
                    </div>
                    <div class="content f-c">
                        <template v-for="(item, index) of productType1">
                            <div class="c-item f-c-a" :key="index" v-if="item.value != ''">
                                <span class="c-item-title f1">{{ item.title }} :</span>
                                <template>
                                    <div v-if="(item.title == '商品主色' || item.title == '产品主色') && item.value != '未设置'" class="f1">
                                        <div class="dominant-color-box" @click="showProductColor">
                                            <div class="dominant-color" :style="{ 'backgroundColor': item.value }"></div>
                                        </div>
                                    </div>
                                    <div v-else-if="item.title == '产品简介' || item.title == '商品简介'" class="f1">
                                        <TextArea :modelValue="item.value" :disabledValue="true"></TextArea>
                                    </div>
                                    <span v-else class="f1">{{ item.value }}</span>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <Dialog title="颜色预览" width="25%" :visible="dialogShowColor" class="dialog-show-color f-cl-c"
            @closeDialog="closeDialog">
            <div class="color-gb" :style="{ background: rgbValue }"></div>
            <div class="color-rgb f-c">
                <span>RGB:</span>
                <span class="color-rgb-value">{{ rgbValue }}</span>
            </div>
            <div class="color-tips">色彩预览会因终端设备的显示效果产生差异,仅供参考使用。</div>
        </Dialog>
    </div>
</template>

<script>

export default {
    name: '',
    props: ['videoUrl', 'proUrlList', 'productType1', 'rgbValue', 'productId', 'pfState' , 'title'],
    data() {
        return {
            dialogShowColor: false,
            isCollect: this.pfState
        }
    },
    watch: {
        pfState() {
            this.isCollect = this.pfState
        }
    },
    methods: {
        // 获取textarea内容
        getTextAreaValue(data) {
            this.ruleForm[data.type] = data.value
        },
        // 展示颜色预览盒子
        showProductColor() {
            this.dialogShowColor = true;
        },
        closeDialog(data) {
            this.dialogShowColor = data;
        },
        handleCollect() {
            let that = this;
            let state = that.isCollect == 'Y' ? 'N' : 'Y';
            that.$http.addCancelProductFavorites({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId: that.productId,
                pfState: state
            }).then(res => {
                if (res.code == 200) {
                    that.isCollect = state
                    that.$common.message(res.data, 'success')
                }
            })
        },
    },
}

</script>
<style lang='less' scoped>
.container {
    .show {
        align-self: flex-start;

        /deep/ .el-carousel {
            width: 300px;
            height: 350px;
            text-align: center;
            position: relative;
            border: 1px solid #999;
            padding: 20px;
            border-radius: 20px;

            .el-carousel__container {
                height: 100%;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

            .el-carousel__arrow {
                background-color: #a9c0dd;
                color: #000;

                .el-icon-arrow-left,
                .el-icon-arrow-right {
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .el-carousel__arrow--left {
                left: -16px;
            }

            .el-carousel__arrow--right {
                right: -16px;
            }

            .el-carousel__indicators {
                position: absolute;
            }

            .el-carousel__button {
                padding: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #fff;
            }

            .el-carousel__indicators--outside {
                bottom: 20px;
            }

        }

    }

    .info {
        width: 100%;
        margin-left: 30px;

        .product-info {
            margin-bottom: 20px;

            .top {
                .title {
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    background-color: #f3f3f3;
                    font-size: 18px;
                    font-weight: bold;
                    padding-left: 20px;
                }

                .product-collect:hover {
                    cursor: pointer;
                }
            }

            .content {
                width: 90%;
                margin: 30px auto;
                border: 1px solid #333;
                flex-wrap: wrap;

                .c-item:not(:last-child) {
                    border-bottom: 1px solid #000;
                }

                .c-item {
                    width: 100%;
                    padding: 12px 0;

                    .c-item-title {
                        text-align: center;
                    }

                    .el-textarea {
                        width: 85%;
                    }

                    .dominant-color-box {
                        width: 50px;
                        height: 20px;
                        border: 1px solid #000;
                        padding: 2px;

                        .dominant-color {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }


    }

}

.dialog-show-color {
    .el-dialog__header {
        font-weight: bold;

    }

    /deep/ .el-dialog__headerbtn .el-dialog__close {
        font-size: 20px;
    }

    /deep/ .el-dialog__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .color-gb {
        width: 50%;
        height: 100px;
        margin-bottom: 25px;
    }

    .color-rgb {
        font-size: 16px;
        margin-bottom: 25px;
        color: #000;

        .color-rgb-value {
            width: 90px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #000;
            border-radius: 5px;
            text-align: center;
            margin-left: 20px;
            color: #000;
        }
    }

    .color-tips {
        width: 50%;
        text-align: center;
        line-height: 22px;
    }
}
</style>
