// 上传前的操作
export const beforeUploadMixin = {
    methods: {
        async beforeUpload(file) {
            let that = this;
            if (that.switchState) {
                that.getImageInfo(file)
            }
            return await new Promise((resolve, reject) => {
                const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'],// 只能上传jpg和png和jpeg的图片
                    isImage = imageTypes.includes(file.type),
                    isLt4M = file.size / 1024 / 1024 > 4.1;// 大小限制为4M 
                if (!isImage) {
                    that.$common.message('上传图片只能是 JPG、JPEG、PNG 格式!', 'error', 1500)
                    return reject(false)
                } else if (isLt4M) {
                    const newfile = that.$common.commonZipPic(file);
                    return resolve(newfile)
                } else {
                    const newFile = that.$common.checkImgSize(file)
                    return resolve(newFile)
                }
            });
        }
    }
}
export const AfterCompletingClippingMixin = {
    methods: {
        async AfterCompletingClippingMixin(file) {
            let that = this;
            return await new Promise((resolve, reject) => {
                const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'],// 只能上传jpg和png和jpeg的图片
                    isImage = imageTypes.includes(file.type),
                    isLt4M = file.size / 1024 / 1024 > 4;// 大小限制为4M 
                if (!isImage) {
                    that.$common.message('上传图片只能是 JPG、JPEG、PNG 格式!', 'error', 1500)
                    return reject(false)
                } else if (isLt4M) {
                    const newfile = that.$common.commonZipPic(file);
                    return resolve(newfile)
                } else {
                    const newFile = that.$common.checkImgSize(file);
                    return resolve(newFile)
                }
            });
        }
    }
}