<template>
    <div class="input_box f-c">
        <TextArea v-if="inputType == 'textarea'" :placeholderValue="placeholderValue" :modelValue="modelvalue"
            @getTextAreaValue="getTextAreaValue" :maxlengthValue="maxlength" :disabledValue="disabledValue"
            :TextAreaisLimit="TextAreaisLimit"></TextArea>
        <el-input v-else type="text" class="f1" :placeholder="placeholderValue" clearable v-model="modelvalue"
            @change="changeInput" :prefix-icon="PrefixIcon" :disabled="disabledValue" @keyup.enter.native="enterSearch"
            @input="HandleInput" :maxlength="maxlength">
        </el-input>
        <el-button v-show="isShowBtn" type="primary" style="margin: 0 20px" icon="el-icon-search" @click="search"
            round>搜索</el-button>
    </div>
</template>

<script>
import { VerificationFormatMethods } from '@/mixins/VerificationFormat'
export default {
    mixins: [VerificationFormatMethods],
    props: {
        placeholderValue: {
            type: String,
            default: '请输入昵称'
        },
        modelValue: {},
        maxlength: {
            type: String,
            default: '10'
        },
        // 是否添加图标
        PrefixIcon: {
            type: String,
            default: ''
        },
        // 输入框对应的自身的类型
        type: {
            type: String,
            default: ''
        },
        // 是否显示搜索按钮
        isShowBtn: {
            type: Boolean,
            default: false
        },
        // 是否禁用
        disabledValue: {
            type: Boolean,
            default: false
        },
        // 输入框的类型 默认是text
        inputType: {
            type: String,
            default: 'text'
        },
        // 文本框是否限制输入
        TextAreaisLimit: {
            type: Boolean,
            default: false
        }

    },
    name: '',
    data() {
        return {
            modelvalue: this.modelValue
        }
    },
    watch: {
        modelValue() {
            this.modelvalue = this.modelValue
        }
    },
    methods: {
        // 有搜索框时必须使用该方法
        // 且需要引入chineseCharactersInput的mixin
        HandleInput(e) {
            this.modelvalue = this.VerificationFormat(e, 3)
            this.$emit('HandleInput', this.VerificationFormat(e, 3))
        },
        // 获取输入的手机
        changeInput(value) {
            this.$emit('getValue', { value, type: this.type })
        },
        // 文本域
        getTextAreaValue(data) {
            this.changeInput(data.value)
        },
        // 回车触发
        enterSearch() {
            if (!this.type) {
                return false;
            }
            this.$emit('enterSearch')
        },
        // 搜索按钮
        search() {
            this.$emit('enterSearch')
        }

    }
}
</script>
<style lang="less">
.input_box {
    width: 100%;
}
</style>
