import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store';

// 注册elementUi
import ElementUI from 'element-ui';
import 'font-awesome/css/font-awesome.min.css'

Vue.use(ElementUI);

import { apiPost } from './utils/service.js'
import { http } from '@/utils/https.js'
import { common, storage, CommonRequests } from '@/utils/common.js'
import { orderClass } from '@/utils/orderStateClass'



import VueCropper from 'vue-cropper';
import VueLazyload from 'vue-lazyload'
// import infiniteScroll from "vue-infinite-scroll";

// 打印
import Print from 'vue-print-nb'
Vue.use(Print); //注册

// 加密
import UrlEncode from '@/utils/UrlEncode'
Vue.prototype.$UrlEncode = UrlEncode;

import '@/styles/common.less';
import '@/styles/color_bg.css';



// 公共组件
import LoginBox from '@/components/LoginBox.vue';
import DialogLogin from '@/components/DialogLogin';
import DialogStanding from '@/components/DialogStanding'; // 身份弹窗
import ProductCard from '@/components/ProductCard.vue';
import Pagination from '@/components/Pagination.vue';//分页器
import Dialog from '@/components/Dialog.vue';//弹框
import GetCode from '@/components/GetCode.vue';//获取验证码
import ForgotPwdForm from '@/components/ForgotPwdForm.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import ChooseCity from "@/components/ChooseCity.vue";
import ChooseSecondLevelCity from "@/components/ChooseSecondLevelCity.vue";
import CascaderCity from "@/components/CascaderCity.vue";
import UploadOtherImage from "@/components/UploadOtherImage.vue"//上传其他图片
import NewUploadOtherImage from "@/components/NewUploadOtherImage.vue"//上传其他图片
import DetailsForm from "@/components/DetailsForm.vue"//详情表单
import SearchBar from "@/components/SearchBar.vue"//详情表单
import TableList from '@/components/TableList.vue';//表格详情列表
import SelectOption from '@/components/SelectOption.vue';//下拉选项
import ModifiableProductDetailTable from '@/components/ModifiableProductDetailTable.vue';//可修改的商品明细表格
import TextArea from '@/components/TextArea.vue';
// input
import PhoneInput from '@/components/Input/PhoneInput.vue';
import CodeInput from '@/components/Input/CodeInput.vue';
import PwdInput from '@/components/Input/PwdInput.vue';
import ChineseCharactersInput from '@/components/Input/ChineseCharactersInput.vue';
import DecimalInput from '@/components/Input/DecimalInput.vue';//长度限制：小数点前最大10位，小数点后最大两位
import salarySelsect from "@/components/salarySelsect.vue";  // 薪资选择器
// 复制---VueClipBoard
import VueClipBoard from 'vue-clipboard2'



import Directives from '@/directives/index'
Vue.use(Directives)

//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


// Vue.use(infiniteScroll);
Vue.use(VueCropper)
Vue.use(VueLazyload)
Vue.use(VueClipBoard)
Vue.prototype.$apiPost = apiPost;//登陆使用了该apiPost
Vue.prototype.$http = http;
Vue.prototype.$common = common;
Vue.prototype.$orderClass = orderClass;
Vue.prototype.$CommonRequests = CommonRequests;
Vue.prototype.$setStorage = storage.setStorage;
Vue.prototype.$getStorage = storage.getStorage;
Vue.prototype.$removeStorage = storage.removeStroage;
Vue.config.productionTip = false;
// 关闭严格模式
Vue.config.strict = false;
Vue.component('LoginBox', LoginBox)
Vue.component('DialogLogin', DialogLogin)
Vue.component('DialogStanding', DialogStanding)
Vue.component('ProductCard', ProductCard)
Vue.component('Pagination', Pagination)
Vue.component('Dialog', Dialog)
Vue.component('GetCode', GetCode)
Vue.component('ForgotPwdForm', ForgotPwdForm)
Vue.component('RegisterForm', RegisterForm)
Vue.component('ChooseCity', ChooseCity)
Vue.component('ChooseSecondLevelCity', ChooseSecondLevelCity)
Vue.component('CascaderCity', CascaderCity)
Vue.component('UploadOtherImage', UploadOtherImage)
Vue.component('NewUploadOtherImage', NewUploadOtherImage)
Vue.component('DetailsForm', DetailsForm)
Vue.component('SearchBar', SearchBar)
Vue.component('TableList', TableList)
Vue.component('SelectOption', SelectOption)
Vue.component('ModifiableProductDetailTable', ModifiableProductDetailTable)
Vue.component('TextArea', TextArea)
Vue.component('PhoneInput', PhoneInput)
Vue.component('CodeInput', CodeInput)
Vue.component('PwdInput', PwdInput)
Vue.component('ChineseCharactersInput', ChineseCharactersInput)
Vue.component('DecimalInput', DecimalInput)
Vue.component('salarySelsect', salarySelsect)




// 路由导航守卫
router.beforeEach((to, from, next) => {
  const token = storage.getStorage('token');
  if (!token) {// 如果token 不存在，弹出登陆框登陆
    if (to.path == '/login') {
      next('/home')
    }
    next();
  } else {//如果token 存在。则继续放行
    // storage.setStorage('loginBoxVisible', false)
    next();
  }

})




new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
