// 社区模块
export default [{
    path: '/CommunityIndex',//社区首页
    name: 'CommunityIndex',
    component: () => import('@/pages/Community/CommunityIndex'),
    meta: { showFooter: true }
}, {
    path: '/PublishableList',//可发布列表
    name: 'PublishableList',
    component: () => import('@/pages/Community/PublishableList'),
    meta: { showFooter: true }
}, {
    path: '/PostEdit',//帖子编辑
    name: 'PostEdit',
    component: () => import('@/pages/Community/PostEdit'),
    meta: { showFooter: true }
}, {
    path: '/PostDetail',//帖子编辑
    name: 'PostDetail',
    component: () => import('@/pages/Community/PostDetail'),
    meta: { showFooter: true }
}, {
    path: '/SupplyAndPurchaseDetail',//供应求购帖子详情
    name: 'SupplyAndPurchaseDetail',
    component: () => import('@/pages/Community/SupplyAndPurchaseDetail'),
    meta: { showFooter: true }
}, {
    path: '/SupplyAndPurchasePublish',//供应求购发布
    name: 'SupplyAndPurchasePublish',
    component: () => import('@/pages/Community/SupplyAndPurchasePublish'),
    meta: { showFooter: true }
}, {
    path: '/RecruitList',//人员管理--人才招聘列表
    name: 'RecruitList',
    component: () => import('@/pages/Community/TalentRecruitment/RecruitList'),
    meta: { showFooter: true }
}, {
    path: '/RecruitPublish',//人员管理--招聘发布
    name: 'RecruitPublish',
    component: () => import('@/pages/Community/TalentRecruitment/RecruitPublish'),
    meta: { showFooter: true }
}, {
    path: '/TalentRecruitmentIndex',//社区--人才招聘首页
    name: 'TalentRecruitmentIndex',
    component: () => import('@/pages/Community/TalentRecruitment/TalentRecruitmentIndex'),
    meta: { showFooter: true }
}, {
    path: '/PersonalCenter',//社区--人才招聘个人中心
    name: 'PersonalCenter',
    component: () => import('@/pages/Community/TalentRecruitment/PersonalCenter'),
    meta: { showFooter: true }
}, {
    path: '/ResumeDetails',//社区--人才招聘个人中心--简历详情
    name: 'ResumeDetails',
    component: () => import('@/pages/Community/TalentRecruitment/ResumeDetails'),
    meta: { showFooter: true }
}, {
    path: '/ControlsResume',//社区--人才招聘个人中心--修改简历或者新增简历
    name: 'ControlsResume',
    component: () => import('@/pages/Community/TalentRecruitment/ControlsResume'),
    meta: { showFooter: true }
}, {
    path: '/TalentRecruitmentList',//社区--人才招聘列表
    name: 'TalentRecruitmentList',
    component: () => import('@/pages/Community/TalentRecruitment/TalentRecruitmentList'),
    meta: { showFooter: true }
}, {
    path: '/TalentRecruitmentDetail',//社区--人才招聘详情
    name: 'TalentRecruitmentDetail',
    component: () => import('@/pages/Community/TalentRecruitment/TalentRecruitmentDetail'),
    meta: { showFooter: true }
}, {
    path: '/TalentMarketList',//社区--人才市场列表
    name: 'TalentMarketList',
    component: () => import('@/pages/Community/TalentRecruitment/TalentMarket/TalentMarketList'),
    meta: { showFooter: true }
},{
    path: '/TalentMarketDetails',//社区--人才市场详情
    name: 'TalentMarketDetails',
    component: () => import('@/pages/Community/TalentRecruitment/TalentMarket/TalentMarketDetails'),
    meta: { showFooter: true }
},{
    path: '/BusinessManagementIndex',//社区--企业管理
    name: 'BusinessManagementIndex',
    component: () => import('@/pages/Community/TalentRecruitment/BusinessManagement/BusinessManagementIndex'),
    meta: { showFooter: true }
},{
    path: '/jodDetails',//社区--职业详情
    name: 'jodDetails',
    component: () => import('@/pages/Community/TalentRecruitment/BusinessManagement/jodDetails'),
    meta: { showFooter: true }
},{
    path: '/ModifyJod',//社区--修改职业
    name: 'ModifyJod',
    component: () => import('@/pages/Community/TalentRecruitment/BusinessManagement/ModifyJod'),
    meta: { showFooter: true }
}, {
    path: '/EnterpriseInternalRecruitment',//社区--企业内部招聘信息及列表
    name: 'EnterpriseInternalRecruitment',
    component: () => import('@/pages/Community/TalentRecruitment/EnterpriseInternalRecruitment'),
    meta: { showFooter: true }
},
{
    path: '/IndustryTrendsList',  //行业动态列表
    name: 'IndustryTrendsList',
    component: () => import('@/pages/Community/IndustryTrends/IndustryTrendsList'),
    meta: { showFooter: true }
}, {
    path: '/IndustryTrendsDetail',  //行业动态帖子
    name: 'IndustryTrendsDetail',
    component: () => import('@/pages/Community/IndustryTrends/IndustryTrendsDetail'),
    meta: { showFooter: true }
},
// MediaApplication
{
    path: '/MediaApplication',  //媒体申请
    name: 'MediaApplication',
    component: () => import('@/pages/Community/IndustryTrends/MediaApplication'),
    meta: { showFooter: true }
}, {
    path: '/MediaInfo',  //媒体信息
    name: 'MediaInfo',
    component: () => import('@/pages/Community/IndustryTrends/MediaInfo'),
    meta: { showFooter: true }
}, {
    path: '/IndustryTrendsPost',  //媒体信息
    name: 'IndustryTrendsPost',
    component: () => import('@/pages/Community/IndustryTrends/IndustryTrendsPost'),
    meta: { showFooter: true }
},{
    path: '/productCommunList',  //产品交流帖子列表
    name: 'productCommunList',
    component: () => import('@/pages/Community/productCommun/productCommunList'),
    meta: { showFooter: true }
},{
    path: '/productCommunDetail',  //产品交流帖子详情
    name: 'productCommunDetail',
    component: () => import('@/pages/Community/productCommun/productCommunDetail'),
    meta: { showFooter: true }
},{
    path: '/productCommunPost',  //产品交流发帖子
    name: 'productCommunPost',
    component: () => import('@/pages/Community/productCommun/productCommunPost'),
    meta: { showFooter: true }
},{
    path: '/MiscellaneousTalkList',  // 识料杂谈列表
    name: 'MiscellaneousTalkList',
    component: () => import('@/pages/Community/MiscellaneousTalk/MiscellaneousTalkList'),
    meta: { showFooter: true }
},{
    path: '/MiscellaneousTalkDetail',  // 识料杂谈帖子详情
    name: 'MiscellaneousTalkDetail',
    component: () => import('@/pages/Community/MiscellaneousTalk/MiscellaneousTalkDetail'),
    meta: { showFooter: true }
},{
    path: '/MiscellaneousTalkPost',  // 发布识料杂谈帖子
    name: 'MiscellaneousTalkPost',
    component: () => import('@/pages/Community/MiscellaneousTalk/MiscellaneousTalkPost'),
    meta: { showFooter: true }
},{
    path: '/LookMaterialsList',  // 找料帖子列表
    name: 'LookMaterialsList',
    component: () => import('@/pages/Community/LookMaterials/LookMaterialsList'),
    meta: { showFooter: true }
},{
    path: '/LookMaterialsDetail',  // 找料帖子详情
    name: 'LookMaterialsDetail',
    component: () => import('@/pages/Community/LookMaterials/LookMaterialsDetail'),
    meta: { showFooter: true }
},{
    path: '/LookMaterialsPost',  // 发布找料帖子
    name: 'LookMaterialsPost',
    component: () => import('@/pages/Community/LookMaterials/LookMaterialsPost'),
    meta: { showFooter: true }
}
]