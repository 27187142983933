<template>
    <div class="TableList">
        <el-divider content-position="left" v-if="showDivider">{{ dividerTitle }}</el-divider>
        <!-- 默认列表 -->
        <div class="list" v-if="showList" :style="{ marginBottom: ListMarginButton }">
            <template v-for="(item, index) of list">
                <div class="item f-c-a " :key="index" v-if="item.value">
                    <span class="item-title" :style="{ width: ItemTitleWidth }">{{ item.title }} :</span>
                    <span class="item-info f1" :style="{ color: ItemInfoColor }"
                        v-if="(item.title == '退货记录' && item.value == 'Y')">
                        <el-button type="primary" @click="checkReturnRecord">查看</el-button>
                    </span>
                    <span v-else-if="(item.title == '关联单号：' && isShowReturnOrderBtn)" class="item-info f1"
                        :style="{ color: ItemInfoColor }">
                        <span style="color:blue">{{ item.value }}</span>
                        <el-button style="margin-left:50px" type="primary" @click="checkRelationBtn">查看</el-button>
                    </span>
                    <el-input v-else-if="item.title == '订单备注' || item.title == '订单操作备注'" type="textarea"
                        :autosize="{ minRows: 2 }" v-model="item.value" :disabled="true">
                    </el-input>
                    <span v-else class="item-info f1 wordBreak-all" :style="{ color: ItemInfoColor }">{{ item.value
                    }}</span>
                </div>
            </template>
        </div>
        <!-- 是否显示表格列表 -->
        <el-table v-if="showTableList" :data="TableList" border :header-cell-style="headerCellStyle">
            <el-table-column v-for="(item, index) of TableColumn" :label="item.label" :prop="item.prop" :key="index">
            </el-table-column>
        </el-table>
        <!-- 是否显示禁止的文本域  可动态设置禁用和使用-->
        <TextArea class="fs-18" v-if="ShowDisabledTextarea" :modelValue="TextareaContent" :disabledValue="true"></TextArea>
        <!-- 自定义列表 -->
        <slot name="definedList"></slot>
    </div>
</template>

<script>


export default {
    name: '',
    props: {
        showDivider: {//是否显示标题
            type: Boolean,
            default: true
        },
        dividerTitle: String,
        showList: {//是否显示默认列表
            type: Boolean,
            default: true
        },
        showTableList: {//是否显示表格列表
            type: Boolean,
            default: false
        },
        TableList: Array,
        TableColumn: Array,
        ShowDisabledTextarea: {//是否显示禁止的文本域   
            type: Boolean,
            default: false
        },
        TextareaContent: {
            type: String,
            default: '无'
        },
        // 关联单号是否可跳转 
        isShowReturnOrderBtn: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        ItemTitleWidth: {
            type: String,
            default: '20%'
        },
        ListMarginButton: {
            type: String,
            default: '50px'
        },
        ItemInfoColor: {
            type: String,
            default: ''
        },
        // 表头样式
        headerCellStyle: {
            type: Object,
            default: () => {
                return { background: '#4e5a6e', color: '#fff' }
            }
        },
    },
    methods: {
        // 查看退货记录
        checkReturnRecord() {
            this.$emit('checkReturnRecord')
        },
        // 退货单-查看关联单号
        checkRelationBtn() {
            this.$emit('checkRelationBtn')
        },
    }
}

</script>
<style lang='less' scoped>
@import "@/assets/css/TableList.less";
</style>
