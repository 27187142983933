<template>
    <div class="product-table">
        <div class="table f-c tableHeader">
            <!-- <div class="span" :style="{ width: productNameWidth }">品名</div>
                                        <div class="span" :style="{ width: ModifyProductNameWidth }">销售名</div> -->
            <div class="span" :style="{ width: ModifyProductNameWidth }">品名</div>
            <div class="span" :style="{ width: ModifyProductNameWidth }">编号</div>
            <div class="span" :style="{ width: unitPriceWidth }">价格</div>
            <div class="span" :style="{ width: quantityWidth }">数量</div>
            <div class="span" :style="{ width: amountWidth }">小计</div>
            <div class="span" :style="{ width: noteWidth }">备注</div>
            <div class="span" v-if="isShowResetBtn" :style="{ width: btnWidth }">
                <el-button size="mini" @click="resetBtn">重置</el-button>
            </div>
            <div class="span" :style="{ width: btnWidth }" v-else></div>
        </div>
        <div class="table f-c" v-for="(item, index) of newList" :key="item.productId">
            <!-- <span class="span" :style="{ width: productNameWidth }">{{ item.productName }}</span> -->
            <!-- <div :style="{ width: ModifyProductNameWidth }" class="span">
                                            <input type="text" class="change_product_name" v-model="item.productName" />
                                        </div> -->
            <!-- 品名 -->
            <el-popover :style="{ width: ModifyProductNameWidth }" placement="top" trigger="click"
                v-model="item.visibleProductName">
                <div class="f-c popover-one">
                    <span>原品名：{{ item.productName }}</span>
                    <el-button round type="parmary" @click="copy('productName', item.productName, index)">使用</el-button>
                </div>
                <div slot="reference" class="span fs-18">
                    <el-input type="textarea" :autosize="{ minRows: 2 }" class="change_product_name fs-18" placeholder="请输入品名"
                        v-model="item.customerUseName" maxlength="30" show-word-limit></el-input>
                </div>
            </el-popover>
            
            <!-- 编号 -->
            <el-popover :style="{ width: ModifyProductNameWidth }" placement="top" trigger="click"
                v-model="item.visibleProductNumber">
                <div class="f-c popover-one">
                    <span>原编号：{{ item.productNumber }}</span>
                    <el-button round type="parmary" @click="copy('productNumber', item.productNumber, index)">使用</el-button>
                </div>
                <div slot="reference" class="span">
                    <el-input type="textarea" :autosize="{ minRows: 2 }" maxlength="30" show-word-limit placeholder="请输入编号"
                        class="change_product_name" v-model="item.customerUseNumber"></el-input>
                </div>
            </el-popover>

            <!-- 价格 -->
            <el-popover  :style="{ width: unitPriceWidth }" placement="top" trigger="click" v-model="item.visibleUnitPrice"
                @show="showUnitPrice(index)">
                <div class="f-cl popover-one">
                    <div class="f-c">
                        <span>原价格：{{ item.originUnitPrice }}</span>
                        <el-button round type="parmary"
                            @click="() => { item.unitPrice = item.originUnitPrice; item.visibleUnitPrice = false; changeNumberInput(item.originUnitPrice, item.unitPrice, item.productId, 'changeUnitPrice') }">使用</el-button>
                    </div>
                    <div class="f-c" v-if="!orderType">上一次交易价格：
                        <span v-show="item.LastTransactionPrice">{{
                            item.valuationCurrency +
                            item.LastTransactionPrice }}</span>
                        <el-button round type="parmary" v-show="!item.LastTransactionPrice"
                            @click="findOrderCompletedUnitPriceInfo(item.productId, index)">查询</el-button>
                        <el-button round type="parmary" v-show="item.LastTransactionPrice"
                            @click="() => { item.unitPrice = item.LastTransactionPrice; item.visibleUnitPrice = false; changeNumberInput(item.LastTransactionPrice, item.unitPrice, item.productId, 'changeUnitPrice') }">使用</el-button>
                    </div>
                </div>
                <el-input-number type="number" slot="reference" class="span" v-model="item.unitPrice" :precision="2" :min="0"
                    @change="(currentValue, oldValue) => { item.visibleUnitPrice = false; changeNumberInput(currentValue, oldValue, item.productId, 'changeUnitPrice') }">
                </el-input-number>
            </el-popover>
            <!-- 现有库存 -->
            <el-popover :style="{ width: quantityWidth }" placement="top" trigger="click"
                v-model="item.visibleInventoryQuantigy">
                <div class="f-c popover-one">
                    <span>现有库存：{{ item.inventoryQuantity }}</span>
                    <el-button round type="parmary" @click="findProductInventory(item.productId, index)">{{
                        item.inventoryQuantity ? '刷新'
                        : '查询' }}</el-button>
                </div>
                <el-input-number type="number" slot="reference" class="span" v-model="item.quantity" :precision="2" :min="0"
                    @change="(currentValue, oldValue) => { item.visibleInventoryQuantigy = false; changeNumberInput(currentValue, oldValue, item.productId, 'changeQuantity') }">
                </el-input-number>
            </el-popover>


            <el-input-number type="number" class="span" :style="{ width: amountWidth }" v-model="item.amount" :precision="2" :min="0"
                @change="(currentValue, oldValue) => changeNumberInput(currentValue, oldValue, item.productId, 'changeRealAmount')">
            </el-input-number>
            <div :style="{ width: noteWidth }" class="span">
                <el-input type="textarea" :autosize="{ minRows: 2 }" maxlength="100" show-word-limit
                    class=" change_product_name" v-model="item.note" placeholder="请输入备注"></el-input>
            </div>
            <span :style="{ width: btnWidth }" class="span delect-btn" @click="delectSelectProduct(item.productId)">
                <el-button type="danger">删除</el-button>
            </span>
        </div>
    </div>
</template>

<script>

export default {
    name: '',
    props: {
        List: {
            type: Array,
            default: () => {
                return []
            }
        },
        isShowResetBtn: {
            type: Boolean,
            default: false
        },
        checkProductCustomerId: {},//选中的客户id
        orderType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // productNameWidth: '10%',
            ModifyProductNameWidth: '20%',
            unitPriceWidth: '14%',
            quantityWidth: '14%',
            amountWidth: '14%',
            noteWidth: '30%',
            btnWidth: '8%',
            newList: this.List,
            getPriceSuccessCustomerId: 0,//请求过上一次成交价格的客户id
        }
    },
    watch: {
        List() {
            this.newList = this.List
        },
        "checkProductCustomerId": {
            deep: true,
            immediate: true,
            handler(newVal) {
                if (this.getPriceSuccessCustomerId != 0 && newVal != this.getPriceSuccessCustomerId) {
                    this.newList.forEach(item => {
                        item.LastTransactionPrice = ''
                    })
                } else if (this.getPriceSuccessCustomerId != 0) {
                    this.newList = this.$getStorage('findOrderCompletedUnitPriceInfo')
                }
            }
        }
    },
    methods: {
        // 显示价格查询上一次交易价格入口
        showUnitPrice(index) {
            if (!this.$getStorage('chooseCustomerId')) {
                this.$nextTick(() => {
                    this.newList[index].LastTransactionPrice = '';
                })
            }
        },
        // 用户查看产品库存接口
        findProductInventory(productId, index) {
            let that = this;
            that.$http.findProductInventory({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId
            }).then(res => {
                if (res.code == 200) {
                    that.newList[index].inventoryQuantity = res.data.inventoryQuantity;
                    that.$setStorage('findOrderCompletedUnitPriceInfo', that.newList)
                    that.$common.message(res.msg, 'success', 1500)
                }
            })
        },
        copy(type, name, index) {
            switch (type) {
                case 'productName'://品名
                    this.newList[index].customerUseName = name;
                    this.newList[index].visibleProductName = false
                    break
                case 'productNumber'://编号
                    this.newList[index].customerUseNumber = name;
                    this.newList[index].visibleProductNumber = false
                    break
            }
        },
        // 用户查看完成订单产品单价信息接口
        findOrderCompletedUnitPriceInfo(productId, index) {
            let that = this,
                checkCustomerId = that.$getStorage('chooseCustomerId');
            if (!checkCustomerId) {
                return that.$common.message('请先指定客户！', 'warning', 1200)
            }
            that.$http.findOrderCompletedUnitPriceInfo({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId,
                customerId: checkCustomerId,
            }).then(res => {
                let { unitPrice, valuationCurrency } = res.data;
                if (res.code == 200) {
                    if (unitPrice != '' && valuationCurrency != '') {
                        that.$nextTick(() => {
                            that.newList[index].LastTransactionPrice = unitPrice;
                            that.newList[index].valuationCurrency = valuationCurrency;
                            that.getPriceSuccessCustomerId = checkCustomerId
                            that.$setStorage('findOrderCompletedUnitPriceInfo', that.newList)
                        })
                    } else {
                        that.$common.message('未查询到该商品与指定客户的交易信息', 'warning', 2000)
                    }

                }
            })
        },
        // 改变数组输入框
        changeNumberInput(currentValue, OldValue, productId, type) {
            this.$emit('changeNumberInput', currentValue, productId, type)
        },
        // 删除选中的产品
        delectSelectProduct(productId) {
            this.$emit('delectSelectProduct', productId)
        },
        // 重置
        resetBtn() {
            this.$emit('resetBtn')
        },

    },
}

</script>
<style lang='less' scoped>
.product-table {
    .table {
        border: 1px solid #DCDFE6;

        .span {
            text-align: center;
            font-size: 14px;
            /deep/.el-textarea {
                font-size: 18px !important;
                // color: red;
            }
        }

        .change_product_name {
            width: 90%;
        }

        span:not(:last-child),
        .span:not(:last-child) {
            border-right: 1px solid #DCDFE6;
        }


        .delect-btn {
            margin: auto;

            .el-button--danger {
                padding: 5px 10px;
            }

            .el-icon-remove-outline {
                font-size: 20px;
            }
        }

        .delect-btn:hover {
            .el-icon-remove-outline {
                color: red;
            }
        }

        /deep/ .el-input-number {
            width: 100%;
        }

        /deep/ .el-input-number__decrease,
        /deep/ .el-input-number__increase {
            background: #b3c2d9;
            color: #000000;
            width: 28px;
            font-size: 12px;
            transform: scale(0.8);
        }

        /deep/ .el-input-number .el-input__inner {
            padding-left: 26px;
            padding-right: 26px;
        }
    }


    .tableHeader {
        background: #F8F8F8;
        color: #333;
        min-height: 60px;
        line-height: 60px;
    }

    .table:not(:last-child) {
        border-bottom: none;
    }
}

.el-popover {
    .popover-one {
        /deep/ .el-button.is-round {
            padding: 5px 10px;
            margin-left: 15px;
        }
    }
}

/deep/ .el-textarea {
    font-size: 12px;
}

/deep/ .el-textarea .el-input__count {
    color: #909399;
    // color: red;
    background: transparent;
    position: absolute;
    font-size: 12px;
    right: 8px;
}

/deep/ .el-textarea__inner {
    padding: 5px 6px 16px;
    line-height: 1.3;
}
</style>
