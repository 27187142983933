<template>
    <div class="search-box">
        <div class="search f-c">
            <div class="f-c search-input">
                <slot name="selectBox1"></slot>
                <slot name="selectBox2"></slot>
                <slot name="inputBox"></slot>
                <el-button class="search-icon" @click="handleImageUploadBox"><i class="el-icon-camera"></i>
                </el-button>
            </div>
            <slot name="buttonBox"></slot>
        </div>
        <ImageUpload @closeUploadBox="closeUploadBox" :style="ImageUploadStyle" v-show="showImageUploadBox"
            :ImageSearchtype="ImageSearchtype" :key="new Date().getTime()" :imageUploadTrends="imageUploadTrends">
        </ImageUpload>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        ImageSearchtype: {
            type: String,
            default: ''
        },
        ImageUploadStyle: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    components: {
        ImageUpload: require('@/components/ImageUpload.vue').default
    },
    data() {
        return {
            showImageUploadBox: false,
            imageUploadTrends: {
                'position': 'absolute',
                'left': '30px'
            }
        }
    },
    created() { },
    methods: {
        // 展开图片上传盒子
        handleImageUploadBox() {
            this.showImageUploadBox = true;
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
    },
}

</script>
<style lang='less' scoped>
.search-box {
    position: relative;
    margin-bottom: 20px;

    .search {
        .search-input {
            position: relative;

            .select-box {
                width: 100px;
                position: absolute;
                left: 0;
            }
            .select-box2 { 
                width: 100px;
                position: absolute;
                left: 100px;
            }

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #DCDFE6;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 210px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }

            .search-icon {
                width: 50px;
                height: 38px;
                line-height: 40px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                right: 1px;
                font-size: 20px;
                border: none;
                outline: none;
                border-left: 1px solid #DCDFE6;
                padding: 0;
                margin: 0;
            }
        }

        el-button {
            border: none;
            margin-left: 5px;
        }
    }
}
</style>
