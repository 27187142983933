<template>
    <div>
        <el-select style="width: 130px;" v-model="minSalary" @change="minChange">
            <el-option v-for="item in salaryList" :key="item.name" :label="item.name" :value="item.name"></el-option>
        </el-select>
        <span class="ml-25 mr-25">——</span>
        <el-select style="width: 130px;" v-model="maxSalary" @change="maxChange">
            <el-option v-for="item in maxsalaryList" :key="item.name" :label="item.name" :value="item.name"></el-option>
        </el-select>
    </div>
</template>

<script>
import salary from "@/assets/salary.json";
export default {
    name: '',
    props: {
        min: {
            default: ''
        },
        max: {
            default: ''
        },
    },
    data() {
        return {
            minSalary: this.min,
            maxSalary: this.max,
            salaryList: [],
            maxsalaryList: [],

        }
    },
    created() {
        this.salaryList = salary;
    },
    methods: {
        minChange(value) {
            this.maxSalary = '';
            let that = this,
                salaryList = that.salaryList;
            let result = salaryList.filter(item => item.name === value);
            that.maxsalaryList = result[0].price;
        },
        maxChange(value) {
            console.log('maxChange',value);
            let that = this;
            let Salary ='';
            if(that.minSalary == '面议' && that.maxSalary == '面议'){
                Salary = '面议'
            }else{
                Salary = that.minSalary + ' - ' + that.maxSalary;
            }
            that.$emit('handlesalarySelsect',Salary,that.minSalary,that.maxSalary);
        },
    },
}
</script>
